import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Form, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import { FaSearch, FaEye, FaPlus, FaSignOutAlt, FaUserPlus, FaUsers, FaSave } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import Modals from '../../components/modals';
import { DecriptToken } from '../../functions';
import { Breadcrumb } from '../../components';
import Msg from '../../components/errors/msg';
import { RequestGet } from '../../requestApi';
import UsuariosNew from './new';


let linqs = [{ nome: "Usuários", link: "/usuarios", ativo: true }]

const Usuarios = () => {
    const [msgErro, setMsgErro] = useState("");
    const [usuarios, setUsuarios] = useState([]);
    const [id, setId] = useState(0)

    const [dados, setDados] = useState({});

    const [sending, setSending] = useState(false);
    const [search, setSearch] = useState("");

    //Abrir modals
    const [openNew, setOpenNew] = useState(false)
    const [openView, setOpenView] = useState(false)

    const saveUser = () => {
        alert("Salvou")
    }

    const list = async () => {
        setMsgErro("");
        //if (search.trim().length > 1) {
        setSending(true);

        const r = await RequestGet("/Usuarios/List?search=" + search)

        if (r.status === 1) {
            setUsuarios(r.data.usuarios)
            if (r.data.usuarios.length === 0)
                setMsgErro(r.message);
        }
        else {
            toast.error(<Msg dados={r.errors} />)
            setMsgErro(r.message);
        }
        setSending(false);
        //}
    };

    const handleSearch = (e) => {
        //e.preventDefault();
        if (e.key === "Enter") {
            list();
        }
    };

    useEffect(() => { list() }, [])

    return (
        <>
            <Breadcrumb title="Usuários" icon={<FaUsers />} links={linqs} /><Row>
                <Col sm={12}>
                    <Card className="shadow">
                        <Card.Body>
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4} className="mb-3">
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Buscar"
                                                    aria-label="Buscar"
                                                    size="sm"
                                                    onKeyDown={handleSearch}
                                                    onChange={(e) => setSearch(e.target.value.toUpperCase())}
                                                    defaultValue={search}
                                                    autoFocus={true}
                                                    style={{ textTransform: "uppercase" }} />
                                                <Button variant="primary" size="sm" onClick={list}>
                                                    <FaSearch />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={8} className="mb-3">
                                            {sending && (
                                                <Spinner
                                                    animation="border"
                                                    size="sm"
                                                    className="float-start mt-2" />
                                            )}
                                            {msgErro !== "" && (
                                                <span className="text-danger float-start mt-1">
                                                    {msgErro}
                                                </span>
                                            )}
                                            {DecriptToken(localStorage.getItem("_token")).role === "Super" ?
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="float-end"
                                                    onClick={() => setOpenNew(true)}
                                                >
                                                    <FaPlus className='mb-1' />
                                                    &nbsp;Novo Usuário
                                                </Button>
                                                : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    {usuarios.length > 0 &&
                                        <div style={{ maxHeight: 450, overflowY: 'scroll' }}>
                                            <Table striped hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="ps-2">Nome</th>
                                                        <th scope="col">Login</th>
                                                        <th scope="col">Tipo</th>
                                                        <th scope="col">Ativo</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {usuarios.map((u, i) => (
                                                        <tr key={i}>
                                                            <td className="ps-2">{u.nome}</td>
                                                            <td>{u.login}</td>
                                                            <td>{u.tipo}</td>
                                                            <td>{u.ativo ? <Badge bg="success">SIM</Badge> : <Badge bg="danger">NÃO</Badge>}</td>
                                                            <td><Link onClick={() => { setId(u.id); setOpenView(true) }}><FaEye color="#003768" /></Link></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {openNew ? <Modals show={openNew} close={setOpenNew} size="lg" icon={<FaUserPlus />} title="Novo Usuário" footer={<BtFooterNew close={setOpenNew} save={saveUser} />}><UsuariosNew dados={dados} setDados={setDados} /></Modals> : null}
            {/* {openView ? <Modals show={openView} close={setOpenView} size="lg" icon={<FaUser />} title="Visualização de Cadastro de Membro" footer={<BtFooter />}><CadastrosMembrosView id={idMembro} /></Modals> : null} */}
        </>
    )
}

export default Usuarios

const BtFooterNew = (props) => {
    return (
        <>
            <Button variant="secondary" size="sm" onClick={() => props.close(false)}><FaSignOutAlt className='mb-1' /> Sair</Button>
            <Button variant="primary" size="sm" onClick={() => props.save()}><FaSave className='mb-1' /> Gravar</Button>
        </>
    )
}
