import React from 'react'
import { Breadcrumb, CardsCadastrosMenu } from '../../components'

const Cadastros = () => {
    return (
        <>
            <Breadcrumb title="Cadastros" links={[{ nome: "Cadastros", link: "/cadastros", ativo: true }]} />
            <CardsCadastrosMenu />
        </>
    )
}

export default Cadastros