import React from 'react'

const NotPermit = () => {
  return (
    <div>
      Usuario nao tem permissao para acessar a pagina
    </div>
  )
}

export default NotPermit
