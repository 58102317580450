import React, { useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Modal, Row, Spinner, Table } from "react-bootstrap";
import { FaSearch, FaEye, FaPlus, FaUser, FaSignOutAlt, FaUserPlus } from "react-icons/fa";
import { Link, Navigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Breadcrumb } from '../../../components';
import Modals from '../../../components/modals';
import { DecriptToken } from '../../../functions';
import { RequestGet } from '../../../requestApi';
import CadastrosMembrosView from './view';
import CadastrosMembrosNew from './new';

const CadastrosMembros = () => {
    const [msgErro, setMsgErro] = useState("");
    const [membros, setMembros] = useState([]);
    const [idMembro, setIdMembro] = useState(0)

    const [sending, setSending] = useState(false);
    const [search, setSearch] = useState("");

    //Abrir modals
    const [openNew, setOpenNew] = useState(false)
    const [openView, setOpenView] = useState(false)

    const list = async () => {
        setMsgErro("");
        if (search.trim().length > 1) {
            setSending(true);

            const r = await RequestGet("/Cadastros/Membros/List?search=" + search)

            if (r.status === 1) {
                setMembros(r.membros)
                if (r.membros.length === 0)
                    setMsgErro(r.message);
            }
            else {
                toast.error(r.errors[0])
                setMsgErro(r.message);
            }
            setSending(false);

            // const r = await RequestApi.get("/Alunos/List?search=" + search)
            //     .then((res) => res)
            //     .catch((err) => err?.response);

            // if (r !== undefined && r.status === 200) {
            //     if (r.data.status === 0) {
            //         setMsgErro(r.data.message);
            //     }
            //     setAlunos(r.data.alunos);
            //     setSending(false);
            //     if (r.data.alunos.length === 0) {
            //         setMsgErro(r.data.message);
            //     }
            // } else {
            //     setMsgErro(r?.data?.status);
            // }
        }
    };

    const handleSearch = (e) => {
        //e.preventDefault();
        if (e.key === "Enter") {
            list();
        }
    };
    //Others
    let linqs = [{ nome: "Cadastros", link: "/cadastros", ativo: false }, { nome: "Membros", link: "/cadastros/membros", ativo: true }]
    const BtFooter = () => {
        return (
            <><Button variant="secondary" size="sm" onClick={() => setOpenView(false)}><FaSignOutAlt /> Sair</Button></>
        )
    }
    return (
        <>
            <Breadcrumb title="Membros da Igreja" icon={<FaUser />} links={linqs} /><Row>
                <Col sm={12}>
                    <Card className="shadow">
                        <Card.Body>
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4} className="mb-3">
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Buscar"
                                                    aria-label="Buscar"
                                                    size="sm"
                                                    onKeyDown={handleSearch}
                                                    onChange={(e) => setSearch(e.target.value.toUpperCase())}
                                                    defaultValue={search}
                                                    autoFocus={true}
                                                    style={{ textTransform: "uppercase" }} />
                                                <Button variant="primary" size="sm" onClick={list}>
                                                    <FaSearch />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={8} className="mb-3">
                                            {sending && (
                                                <Spinner
                                                    animation="border"
                                                    size="sm"
                                                    className="float-start mt-2" />
                                            )}
                                            {msgErro !== "" && (
                                                <span className="text-danger float-start mt-1">
                                                    {msgErro}
                                                </span>
                                            )}
                                            {DecriptToken(localStorage.getItem("_token")).role === "Super" ?
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="float-end"
                                                    onClick={() => setOpenNew(true)}
                                                >
                                                    <FaPlus />
                                                    &nbsp;Novo Membro
                                                </Button>
                                                : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    {membros.length > 0 &&
                                        <div style={{ maxHeight: 450, overflowY: 'scroll' }}>
                                            <Table striped hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="ps-2">Nome</th>
                                                        <th scope="col">Nome Completo</th>
                                                        <th scope="col">Família</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {membros.map((m, i) => (
                                                        <tr key={i}>
                                                            <td className="ps-2">{m.conhecidoComo}</td>
                                                            <td>{m.nome}</td>
                                                            <td>{m.familia}</td>
                                                            <td>{m.status}</td>
                                                            <td><Link onClick={() => { setIdMembro(m.id); setOpenView(true) }}><FaEye color="#003768" /></Link></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {openNew ? <Modals show={openNew} close={setOpenNew} size="xl" icon={<FaUserPlus />} title="Novo Membro" footer={<BtFooterNew close={setOpenNew} />}><CadastrosMembrosNew close={setOpenNew} /></Modals> : null}
            {openView ? <Modals show={openView} close={setOpenView} size="lg" icon={<FaUser />} title="Visualização de Cadastro de Membro" footer={<BtFooter />}><CadastrosMembrosView id={idMembro} /></Modals> : null}
        </>
    )
}

export default CadastrosMembros

const BtFooterNew = (props) => {
    return (
        <><Button variant="secondary" size="sm" onClick={() => props.close(false)}><FaSignOutAlt /> Sair</Button></>
    )
}