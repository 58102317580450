import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const RequestApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL

})

//Erros
let e401 = "Login deve ser realizado"
let e403 = "Usuário não tem permissão para acessar os dados"
let e404 = "Página não encontrada"
let e502 = "Bad Gateway invalid response"
let eReq = "Erro Servidor"
let eErr = "Servidor não encontrado. Verifique a conexão com a internet ou tente novamente mais tarde! "
let eErs = "Servidor não encontrado. Verifique a conexão com a internet ou tente mais tarde!"
let eOff = "Internet Off. Verifique a conexão da sua internet!!!"

const ResponseRequest = (err) => {
    toast.dismiss()
    let d = {};
    if (err.response) {
        // The request was made, but the server responded with a status code that falls out of the 2xx range
        const { status } = err.response;

        if (status === 401) {
            // The client must authenticate itself to get the requested response
            localStorage.removeItem("_token");
            toast.error(e401);
            d = { status: 3, message: e401, errors: [{ e401 }] }
            window.location.href = "/login"
        }
        else if (status === 403) {
            // The client must authenticate itself to get the requested response
            toast.error(e403);
            d = { status: 0, message: e403, errors: [{ e403 }] }
            //window.location.reload();            
        }
        else if (status === 404) {
            // The client must authenticate itself to get the requested response
            toast.error(e404 + ". " + err.message);
            d = { status: 0, message: e404, errors: [err.message] }
        }
        else if (status === 502) {
            // The server got an invalid response
            toast.error(e502);
            d = { status: 4, message: e502, errors: [{ e502 }] }
        }
    }
    else if (err.request) {
        if (err.code === "ERR_NETWORK") {
            // The request was made, but no response was received
            var eru = eErr + err.code
            toast.error(eru, { autoClose: 15000 });
            d = { status: 4, message: eru, errors: [eru] }
        }
        else {
            toast.error(eReq);
            d = { status: 4, message: eReq, errors: [{ eReq }] }
        }
    }
    else {
        // Some other error
        toast.error(eReq);
        d = { status: 4, message: eReq, errors: [{ eReq }] }
    }
    return d;
}

const RequestGet = async (url) => {
    if (navigator.onLine) {
        RequestApi.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("_token")}`
        return await RequestApi.get(url).then((res) => res.data).catch((err) => ResponseRequest(err));
    }
    else {
        toast.dismiss()
        toast.error(eOff);
        return { status: 0, message: eOff, errors: [{ eOff }] }
    }
}

const RequestPost = async (url, json) => {
    if (navigator.onLine) {
        RequestApi.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("_token")}`;
        return await RequestApi.post(url, json).then((res) => res.data).catch((err) => ResponseRequest(err));
    }
    else {
        toast.dismiss()
        toast.error(eOff);
        return { status: 4, message: eOff, errors: [{ eOff }] }
    }
}

export { RequestGet, RequestPost }