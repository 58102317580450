import React, { useEffect, useState } from 'react'
import { Row, Col, Card, InputGroup, Button, Spinner, Form, Table } from 'react-bootstrap'
import { FaEye, FaHouseUser, FaMap, FaPlus, FaSave, FaSearch, FaSignOutAlt } from 'react-icons/fa'
import { MdFamilyRestroom } from 'react-icons/md'
import { Link, Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Breadcrumb from '../../../components/breadcrumb'
import Msg from '../../../components/errors/msg'
import Modals from '../../../components/modals'
import ModalNotifications from '../../../components/modals/notifications'
import { RequestGet, RequestPost } from '../../../requestApi'
import CadastrosFamiliasMap from './map'
import CadastrosFamiliasNew from './new'
import CadastrosFamiliasView from './view'

const CadastrosFamilias = () => {
    const [familias, setFamilias] = useState([])
    const [msgErro, setMsgErro] = useState("");
    const [sending, setSending] = useState(false);
    const [search, setSearch] = useState("");
    const [pga, setPga] = useState(1);
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [openNew, setOpenNew] = useState(false)
    const [idChefe, setIdChefe] = useState(0);
    const [dados, setDados] = useState({ nome: "", latitude: null, longitude: null, membros: [] })
    //const [nome, setNome] = useState("")

    const [openView, setOpenView] = useState(false)
    const [idFamilia, setIdFamilia] = useState(0)

    const [openMap, setOpenMap] = useState(false)

    //Notificacato
    const [notification, setNotification] = useState(false)
    const [txt, setTxt] = useState(null);
    const [msg, setMsg] = useState(null);

    const list = async () => {
        setMsgErro("");

        setSending(true);

        const r = await RequestGet("/Cadastros/Familias/List?search=" + search + "&PageNumber=" + pga)

        if (r.status === 1) {
            setFamilias(r.data.familias)
            if (r.data.familias.length === 0) {
                setMsgErro(r.message);
                setPga(r.data.paginationFilter.pageNumber)
                setTotalRegistros(r.data.paginationFilter.totalRegisters)
            }
            else {
                setPga(r.data.paginationFilter.pageNumber)
                setTotalRegistros(r.data.paginationFilter.totalRegisters)
            }
        }
        else if (r.status === 3) {
            <Navigate to="/login" replace={true} />
        }
        else {
            setMsgErro(r.message);
            toast.error(r.errors[0])
        }
        setSending(false);

    };

    const saveFamilia = async () => {
        setNotification(true)
        setTxt("Enviando Dadosd");
        setMsg(null)

        const r = await RequestPost("/Cadastros/Familias/New", dados)

        if (r.status === 1) {
            toast.success(r.message)
            setNotification(false)
            await list()
            setOpenNew(false)
        }
        else {
            setMsg(<Msg dados={r.errors} />)
            //toast.update(id, { render: <Msg dados={r.erros} />, type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_CENTER, closeButton: true });
        }
        console.log(dados)
    }

    const handleSearch = (e) => {
        //e.preventDefault();
        if (e.key === "Enter") {
            if (search.trim().length > 0) list();
        }
    };
    let linqs = [{ nome: "Cadastros", link: "/cadastros", ativo: false }, { nome: "Familias", link: "/cadastros/familias", ativo: true }]
    useEffect(() => { list() }, [])
    return (
        <>
            <Breadcrumb title="Famílias da Igreja" icon={<MdFamilyRestroom />} links={linqs} />
            <Row>
                <Col sm={12}>
                    <Card className="shadow">
                        <Card.Body>
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4} className="mb-3">
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Buscar"
                                                    aria-label="Buscar"
                                                    size="sm"
                                                    onKeyDown={handleSearch}
                                                    onChange={(e) => setSearch(e.target.value.toUpperCase())}
                                                    defaultValue={search}
                                                    autoFocus={true}
                                                    style={{ textTransform: "uppercase" }} />
                                                <Button variant="primary" size="sm" onClick={list}>
                                                    <FaSearch />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={8} className="mb-3">
                                            <Row>
                                                <Col sm={5} >
                                                    {sending && (
                                                        <Spinner
                                                            animation="border"
                                                            size="sm"
                                                            className="float-start mt-2" />
                                                    )}
                                                    {msgErro !== "" && (
                                                        <span className="text-danger float-start mt-1">
                                                            {msgErro}
                                                        </span>
                                                    )}
                                                </Col>
                                                <Col sm={4} >
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        className="float-end"
                                                        onClick={() => { setOpenMap(true) }}
                                                    >
                                                        <FaMap />
                                                        &nbsp;Mapa das Familias
                                                    </Button>
                                                </Col>
                                                <Col sm={3}>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        className="float-end"
                                                        onClick={() => { setIdChefe(0); setDados({ nome: "", latitude: null, longitude: null, membros: [] }); setOpenNew(true) }}
                                                    >
                                                        <FaPlus />
                                                        &nbsp;Nova Família
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="ps-2">Nome Família</th>
                                                <th scope="col">Chefe Família</th>
                                                <th scope="col">Membros</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {familias.map((f, i) => (
                                                <tr key={i}>
                                                    <td className="ps-2">{f.nome}</td>
                                                    <td>{f.chefeFamilia}</td>
                                                    <td>{f.qtde}</td>
                                                    <td><Link onClick={() => { setIdFamilia(f.id); setOpenView(true) }} title="Visualizar Família"><FaEye color="#003768" /></Link></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        {totalRegistros > 0 &&
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4}>{totalRegistros}</td>
                                                </tr>
                                            </tfoot>
                                        }
                                    </Table>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {openNew ? <Modals show={openNew} close={setOpenNew} icon={<FaHouseUser />} size="md" title="Novo Grupo Familiar" footer={<><Button variant="secondary" size="sm" onClick={() => setOpenNew(false)}><FaSignOutAlt />Sair</Button>{idChefe > 0 && <Button variant="primary" size="sm" onClick={saveFamilia}><FaSave /> Gravar</Button>}</>}><CadastrosFamiliasNew idChefe={setIdChefe} dados={dados} setDados={setDados} /></Modals> : null}
            {notification ? (<ModalNotifications show={notification} close={setNotification} texto={txt} message={msg} />) : null}
            {openView ? <Modals show={openView} close={setOpenView} icon={<FaHouseUser />} size="lg" title="Grupo Familiar" footer={<><Button variant="secondary" size="sm" onClick={() => setOpenView(false)}><FaSignOutAlt />Sair</Button></>}><CadastrosFamiliasView id={idFamilia} /></Modals> : null}
            {openMap ? <Modals show={openMap} close={setOpenMap} icon={<FaMap />} size="xl" title="Mapa das Famílias" footer={<><Button variant="secondary" size="sm" onClick={() => setOpenMap(false)}><FaSignOutAlt />Sair</Button></>}><CadastrosFamiliasMap /></Modals> : null}
        </>
    )
}

export default CadastrosFamilias