import React from 'react'

const Super = () => {
    return (
        <div>
            Somente super pode acessar
        </div>
    )
}

export default Super
