import React, { useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { FaSave, FaSignOutAlt, FaWhatsapp } from 'react-icons/fa'
import { MdOutlinePermContactCalendar } from 'react-icons/md'
import { toast } from 'react-toastify'
import Msg from '../../../../components/errors/msg'
import ModalNotifications from '../../../../components/modals/notifications'
import { RequestPost } from '../../../../requestApi'

const Contatos = (props) => {
    //Notificacato
    const [notification, setNotification] = useState(false)
    const [txt, setTxt] = useState(null);
    const [msg, setMsg] = useState(null);

    const getData = (e) => {
        const { name, value } = e.target
        props.setDados({ ...props.dados, [name]: value })
    }
    const saveDados = async () => {
        setNotification(true)
        setTxt("Enviando Dados");
        setMsg(null)

        const r = await RequestPost("/Cadastros/Membros/Update/Contatos/" + props.dados.id, props.dados)

        if (r.status === 1) {
            props.setData(r.data);
            setNotification(false)
            toast.success(r.message)
            props.close(false)
        }
        else {
            setMsg(<Msg dados={r.errors} />)
        }
    }
    const BtFooterSave = () => {
        return <Card.Footer>
            <>
                <Button size='sm' className='float-end ms-2' onClick={saveDados} ><FaSave className='mb-1' /> Gravar</Button>
                <Button size='sm' variant='secondary' className='float-end' onClick={() => props.close(false)} ><FaSignOutAlt className='mb-1' /> Sair</Button>
            </>
        </Card.Footer>
    }
    return (
        <>
            <Card className='mb-2 shadow'>
                <Card.Header><MdOutlinePermContactCalendar /> Contatos</Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col lg={2} md={4}>
                            <Form.Group controlId="tel">
                                <Form.Label className="mb-0">Telefone</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        // as={ReactInputMask}
                                        type="text"
                                        size="sm"
                                        style={{ textTransform: "uppercase" }}
                                        name="tel"
                                        onChange={(e) => { getData(e) }}
                                        value={props.dados.tel ? props.dados.tel : ""}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col lg={10} md={8}>
                            <Form.Group controlId="email">
                                <Form.Label className="mb-0">E-mail</Form.Label>
                                <Form.Control type="email" size="sm" maxLength={50} style={{ textTransform: "lowercase" }} name="email" onChange={getData} value={props.dados.email ? props.dados.email : ""} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
                {props.footer ? props.footer === "no" ? null : <Card.Footer>{props.footer}</Card.Footer> : <BtFooterSave />}
            </Card>
            {notification ? (<ModalNotifications show={notification} close={setNotification} texto={txt} message={msg} />) : null}
        </>
    )
}

export default Contatos
