import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { BsPersonBoundingBox } from 'react-icons/bs'
import { FaHouseUser, FaSave, FaSearch, FaSignOutAlt } from 'react-icons/fa'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import sexos from '../../../../assets/sexos.json'
import AuxiliaresCidades from '../../../../components/auxiliares/cidades'
import Msg from '../../../../components/errors/msg'
import Modals from '../../../../components/modals'
import ModalLoading from '../../../../components/modals/loading'
import ModalNotifications from '../../../../components/modals/notifications'
import { RequestGet, RequestPost } from '../../../../requestApi'

const DadosParentes = (props) => {
    const [ldng, setLdng] = useState(false)

    const getData = (e) => {
        const { name, value } = e.target;
        if (name === "idGrauParentesco")
            props.setDados({ ...props.dados, [name]: value, parentesco: e.target.options[e.target.selectedIndex].text });
        else
            props.setDados({ ...props.dados, [name]: value });
    };
    //Notifications
    const [notification, setNotification] = useState(false)
    const [txt, setTxt] = useState("")
    const [msg, setMsg] = useState(null)

    const [doencasCronicas, setDoencasCronicas] = useState([]);
    const [escolaridades, setEscolaridades] = useState([]);
    const [estadoCivil, setEstadoCivil] = useState([]);
    const [nacionalidades, setNacionalidades] = useState([]);
    const [necessidadesEspeciais, setNecessidadesEspeciais] = useState([]);
    const [racas, setRacas] = useState([]);
    const [situacoesProfissionais, setSituacoesProfissionais] = useState([]);
    const [grauParentescos, setGrauParentescos] = useState([]);

    const [openCidade, setOpenCidade] = useState(false)
    const [cidadeNascimento, setCidadeNascimento] = useState({ id: null, cidade: null, uf: null })
    //const [ufNascimento, setUfNascimento] = useState(props.dados.ufNascimento ? props.dados.ufNascimento : "N")
    //const [idCidadeNascimento, setIdCidadeNascimento] = useState(props.dados.idCidadeNascimento ? props.dados.idCidadeNascimento : 0);

    const saveNewEdit = async () => {
        setNotification(true)
        setTxt("Enviando Dados");
        setMsg(null)
        //To int os ids necessarios
        props.dados.idEstadoCivil = parseInt(props.dados.idEstadoCivil)
        props.dados.idRaca = parseInt(props.dados.idRaca)
        props.dados.idNacionalidade = parseInt(props.dados.idNacionalidade)
        props.dados.idEscolaridade = parseInt(props.dados.idEscolaridade)
        props.dados.idSituacaoProfissional = parseInt(props.dados.idSituacaoProfissional)
        props.dados.idGrauParentesco = parseInt(props.dados.idGrauParentesco)
        props.dados.idNecessidadeEspecial = parseInt(props.dados.idNecessidadeEspecial)
        props.dados.idDoencaCronica = parseInt(props.dados.idDoencaCronica)
        props.dados.idStatus = parseInt(props.dados.idStatus)

        if (props.dados.dtNascimento === undefined || props.dados.dtNascimento === "")
            props.dados.dtNascimento = null
        console.log("dadosparentes antes gravar")
        console.log(props.dados)

        //const id = toast.loading(" Enviando dados...")

        const r = await RequestPost("/Cadastros/Membros/Parentes/NewEdit", props.dados)

        if (r.status === 1) {
            //props.setDados({ ...props.dados, id: r.id });
            props.dados.id = r.id
            console.log("dadosparentes depois gravar")
            console.log(props.dados)
            props.handleParente()
            setNotification(false)
            toast.success(r.message)
        }
        else {
            setMsg(<Msg dados={r.errors} />)
            //toast.update(id, { render: <Msg dados={r.erros} />, type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_CENTER, closeButton: true });
        }
    }


    useEffect(() => {
        const loadDados = async () => {
            setLdng(true)
            //toast.dismiss()

            const id = toast.loading(" Carregando Tabelas...")

            const r = await RequestGet("/Cadastros/Membros/New")

            if (r.status === 1) {
                setDoencasCronicas(r.doencasCronicas)
                setEscolaridades(r.escolaridades)
                setEstadoCivil(r.estadoCivil)
                setNacionalidades(r.nacionalidades)
                setNecessidadesEspeciais(r.necessidadesEspeciais)
                setRacas(r.racas)
                setSituacoesProfissionais(r.situacoesProfissionais)
                setGrauParentescos(r.grauParentescos)
                setLdng(false)
                toast.dismiss()
                //toast.update(id, { render: r.message, type: "success", isLoading: false, autoClose: 100, closeButton: true });
            }
            else {
                toast.update(id, { render: <Msg dados={r.errors} />, type: "error", isLoading: false, autoClose: 15000, closeButton: true });
            }
        }
        loadDados()
    }, [])

    useEffect(() => {
        props.setDados({ ...props.dados, idCidadeNascimento: cidadeNascimento.id, cidadeNascimento: cidadeNascimento.cidade, ufNascimento: cidadeNascimento.uf })
    }, [cidadeNascimento])

    const BtFooterSave = () => {
        return <Card.Footer>
            <>
                <Button size='sm' className='float-end ms-2' onClick={saveNewEdit} ><FaSave className='mb-1' /> Gravar</Button>
                <Button size='sm' variant='secondary' className='float-end' onClick={() => props.close(false)} ><FaSignOutAlt className='mb-1' /> Sair</Button>
            </>
        </Card.Footer>
    }

    return (
        <>
            <Card className='mb-2 shadow'>
                <Card.Header><BsPersonBoundingBox /> Dados Cadastrais</Card.Header>
                <Card.Body>
                    <Row className="mb-1">
                        <Col sm={5}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Nome Completo <span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" maxLength={50} placeholder="Nome completo" style={{ textTransform: "uppercase" }} size='sm' name="nome" value={props.dados.nome ? props.dados.nome : ""} onChange={getData} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Conhecido(a) Como</Form.Label>
                                <Form.Control type="text" placeholder="Conhecido Como" maxLength={30} style={{ textTransform: "uppercase" }} size='sm' name="conhecidoComo" value={props.dados.conhecidoComo ? props.dados.conhecidoComo : ""} onChange={getData} />
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId="dtEntrada">
                                <Form.Label className="mb-0">Dt. Nascimento <span className="text-danger">*</span></Form.Label>
                                <Form.Control type="date" size="sm" name="dtNascimento" value={props.dados.dtNascimento === null || props.dados.dtNascimento === undefined ? "" : props.dados.dtNascimento} onChange={getData} />
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Grau de Parentesco <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="idGrauParentesco" onChange={getData} value={props.dados.idGrauParentesco === null || props.dados.idGrauParentesco === undefined ? 0 : props.dados.idGrauParentesco}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    {grauParentescos.map((e, i) => (<option key={i} value={e.id} >{e.nome.toUpperCase()}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col sm={2}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Sexo <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="sexo" onChange={getData} value={props.dados.sexo === null || props.dados.sexo === undefined ? 0 : props.dados.sexo}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    {sexos.map((s, i) => (<option key={i} value={s.id} >{s.nome.toUpperCase()}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Estado Civil <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="idEstadoCivil" onChange={getData} value={props.dados.idEstadoCivil === null || props.dados.idEstadoCivil === undefined ? 0 : props.dados.idEstadoCivil}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    {estadoCivil.map((e, i) => (<option key={i} value={e.id} >{e.nome.toUpperCase()}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Escolaridade <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="idEscolaridade" onChange={getData} value={props.dados.idEscolaridade === null || props.dados.idEscolaridade === undefined ? 0 : props.dados.idEscolaridade}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    {escolaridades.map((e, i) => (<option key={i} value={e.id} >{e.nome.toUpperCase()}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Nacionalidade <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="idNacionalidade" onChange={getData} value={props.dados.idNacionalidade === null || props.dados.idNacionalidade === undefined ? 0 : props.dados.idNacionalidade}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    {nacionalidades.map((e, i) => (<option key={i} value={e.id} >{e.nome.toUpperCase()}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Raça/Cor <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="idRaca" onChange={getData} value={props.dados.idRaca === null || props.dados.idRaca === undefined ? 0 : props.dados.idRaca}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    {racas.map((r, i) => (<option key={i} value={r.id} >{r.nome.toUpperCase()}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col sm={3}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Situação Profissional <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="idSituacaoProfissional" onChange={getData} value={props.dados.idSituacaoProfissional === null || props.dados.idSituacaoProfissional === undefined ? 0 : props.dados.idSituacaoProfissional}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    {situacoesProfissionais.map((e, i) => (<option key={i} value={e.id} >{e.nome.toUpperCase()}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Necessidade Especial <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="idNecessidadeEspecial" onChange={getData} value={props.dados.idNecessidadeEspecial === null || props.dados.idNecessidadeEspecial === undefined ? 0 : props.dados.idNecessidadeEspecial}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    {necessidadesEspeciais.map((e, i) => (<option key={i} value={e.id} >{e.nome.toUpperCase()}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Doença Cronica <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="idDoencaCronica" onChange={getData} value={props.dados.idDoencaCronica === null || props.dados.idDoencaCronica === undefined ? 0 : props.dados.idDoencaCronica}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    {doencasCronicas.map((e, i) => (<option key={i} value={e.id} >{e.nome.toUpperCase()}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Status <span className="text-danger">*</span></Form.Label>
                                <Form.Select size="sm" name="idStatus" onChange={getData} value={props.dados.idStatus === null || props.dados.idStatus === undefined ? 0 : props.dados.idStatus}>
                                    <option disabled value={0} className="text-muted">-- Selecione --</option>
                                    <option key={0} value={2} >FREQUENTE</option>
                                    <option key={1} value={3} >NÃO FREQUENTE</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col sm={6}>
                            <Form.Group controlId="cep">
                                <Form.Label className="mb-0">Cidade Nascimento</Form.Label>
                                <InputGroup>
                                    <Button variant="light" size="sm" onClick={() => setOpenCidade(true)} className="border">
                                        <FaSearch color="#babbbc" />
                                    </Button>
                                    <Form.Control
                                        // as={ReactInputMask}
                                        type="text"
                                        size="sm"
                                        style={{ textTransform: "uppercase" }}
                                        value={props.dados.cidadeNascimento && props.dados.ufNascimento ? props.dados.cidadeNascimento + " / " + props.dados.ufNascimento : ""}
                                        disabled
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Obs.</Form.Label>
                            <Form.Control as="textarea" rows={3} name="obs" onChange={getData} />
                        </Form.Group>
                    </Row> */}
                </Card.Body>
                {props.footer ? props.footer === "no" ? null : <Card.Footer>{props.footer}</Card.Footer> : <BtFooterSave />}
            </Card>
            {ldng ? <ModalLoading show={ldng} /> : null}
            {openCidade ? (<Modals show={openCidade} close={setOpenCidade} icon={<FaHouseUser />} title="Cidade Nascimento" footer={<Button variant="secondary" size="sm" onClick={() => setOpenCidade(false)}><FaSignOutAlt />Sair</Button>}><AuxiliaresCidades uf={props.dados.ufNascimento} dados={cidadeNascimento} setDados={setCidadeNascimento} close={setOpenCidade} /></Modals>) : null}
            {notification ? (<ModalNotifications show={notification} close={setNotification} texto={txt} message={msg} />) : null}
        </>
    )
}

export default DadosParentes
