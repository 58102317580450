import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FaHouseUser, FaSave, FaSignOutAlt, FaUserPlus } from 'react-icons/fa'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Breadcrumb } from '../../../components'
import AuxiliaresCidades from '../../../components/auxiliares/cidades'
import Msg from '../../../components/errors/msg'
import Modals from '../../../components/modals'
import ModalNotifications from '../../../components/modals/notifications'
import { RequestGet, RequestPost } from '../../../requestApi'
import Dados from './cards/dados'
import DadosParentes from './cards/dadosparentes'

const CadastrosMembrosNewParente = (props) => {
    const [dados, setDados] = useState({ id: 0, idMembro: props.idchefe, idEstadoCivil: 0, idRaca: 0, idNacionalidade: 0, idEscolaridade: 0, idSituacaoProfissional: 0, idGrauParentesco: 0, idNecessidadeEspecial: 0, idDoencaCronica: 0, idStatus: 0 });

    //Notificacato
    const [notification, setNotification] = useState(false)
    const [txt, setTxt] = useState(null);
    const [msg, setMsg] = useState(null);

    const handleParente = () => {
        console.log(dados)
        props.dados.id = dados.id
        props.dados.nome = dados.nome
        props.dados.conhecidoComo = dados.conhecidoComo
        props.dados.idGrauParentesco = dados.idGrauParentesco
        props.dados.parentesco = dados.parentesco
        //props.setDados({ ...props.dados, id: dados.id, nome: dados.nome, conhecidoComo: dados.conhecidoComo, parentesco: dados.parentesco })
        console.log("newparentes")
        console.log(props.dados)
        props.handleDepNb()
    }

    return (
        <>
            <DadosParentes setDados={setDados} dados={dados} handleParente={handleParente} close={props.close} />
            {/* <Card className="shadow">
                <Card.Footer>
                    <Button onClick={saveNew} size={'sm'} className="float-end"><FaSave className='mb-1' /> Gravar</Button>
                </Card.Footer>
            </Card> */}
            {notification ? (<ModalNotifications show={notification} close={setNotification} texto={txt} message={msg} />) : null}
        </>
    )
}
export default CadastrosMembrosNewParente
