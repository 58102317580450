import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RequestGet } from '../../requestApi';


let brrs = []

const AuxiliaresBairros = (props) => {
    const [idCidade, setIdCidade] = useState(props.idCidade)
    const [bairros, setBairros] = useState([]);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        const loadDados = async () => {
            toast.dismiss()
            setLoading(true);

            const id = toast.loading(" Carregando Bairros...")

            const r = await RequestGet("/Tabelas/Bairros/Cidade/" + idCidade)

            if (r.status === 1) {
                brrs = r.bairros;
                setBairros(r.bairros)
                toast.dismiss()
            }
            else {
                toast.update(id, { render: r.erros[0], type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_RIGHT, closeButton: true });
                //toast.error(r.erros[0])
            }
            setLoading(false);
        }
        if (idCidade !== 0)
            loadDados()
    }, [idCidade])

    const handleGetBairro = (id, bairro, regiao) => {
        props.setDados({ id: id, bairro: bairro.toUpperCase(), regiao: regiao })
        props.close(false)
    }

    const [busca, setBusca] = useState("")

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Bairro <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="text" size="sm" style={{ textTransform: "uppercase" }} onChange={(e) => setBusca(e.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{ height: 375, overflowX: 'scroll' }}>
                        {loading ? <Spinner animation="border" size="sm" className="float-start mt-2" title="Carregando cidades" /> :
                            <Table striped hover size="sm">
                                <tbody>
                                    {/* {bairros.map((b, i) => (
                                        <tr key={i}>
                                            <td className="ps-2">{b.nome}</td>
                                            <td>{b.regiao}</td>
                                            <td><Link onClick={() => handleGetBairro(b.id, b.nome, b.regiao)}><FaCheck color="#003768" /></Link></td>
                                        </tr>
                                    ))} */}
                                    {bairros.filter(item => item.nome.includes(busca.toUpperCase())).map((b, i) => (
                                        <tr key={i}>
                                            <td className="ps-2">{b.nome}</td>
                                            <td>{b.regiao}</td>
                                            <td><Link onClick={() => handleGetBairro(b.id, b.nome, b.regiao)}><FaCheck color="#003768" /></Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default AuxiliaresBairros
