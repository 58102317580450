import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { FaHouseUser, FaSave, FaSignOutAlt, FaUserPlus } from 'react-icons/fa'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Breadcrumb } from '../../../components'
import AuxiliaresCidades from '../../../components/auxiliares/cidades'
import Msg from '../../../components/errors/msg'
import Modals from '../../../components/modals'
import ModalNotifications from '../../../components/modals/notifications'
import { RequestGet, RequestPost } from '../../../requestApi'
import Contatos from './cards/contatos'
import Dados from './cards/dados'
import Endereco from './cards/endereco'

const CadastrosMembrosNew = (props) => {
    const [dados, setDados] = useState({ idIgreja: 1, idEstadoCivil: 0, idRaca: 0, idNacionalidade: 0, idEscolaridade: 0, idSituacaoProfissional: 0, idSituacaoMoradia: 0, idNecessidadeEspecial: 0, idDoencaCronica: 0 });
    const [openCidade, setOpenCidade] = useState(false);
    const [openCidade2, setOpenCidade2] = useState(false);
    const [openCidade3, setOpenCidade3] = useState(false);

    //Notificacato
    const [notification, setNotification] = useState(false)
    const [txt, setTxt] = useState(null);
    const [msg, setMsg] = useState(null);

    const saveNew = async () => {
        setNotification(true)
        setTxt("Enviando Dados");
        setMsg(null)
        //To int os ids necessarios
        dados.idEstadoCivil = parseInt(dados.idEstadoCivil)
        dados.idRaca = parseInt(dados.idRaca)
        dados.idNacionalidade = parseInt(dados.idNacionalidade)
        dados.idEscolaridade = parseInt(dados.idEscolaridade)
        dados.idSituacaoProfissional = parseInt(dados.idSituacaoProfissional)
        dados.idSituacaoMoradia = parseInt(dados.idSituacaoMoradia)
        dados.idNecessidadeEspecial = parseInt(dados.idNecessidadeEspecial)
        dados.idDoencaCronica = parseInt(dados.idDoencaCronica)

        if (dados.dtNascimento === undefined || dados.dtNascimento === "")
            dados.dtNascimento = null

        console.log(dados)

        //const id = toast.loading(" Enviando dados...")

        const r = await RequestPost("/Cadastros/Membros/New", dados)

        if (r.status === 1) {
            setNotification(false)
            toast.success(r.message)
        }
        else if (r.status === 3) {
            <Navigate to="/login" replace={true} />
        }
        else {
            setMsg(<Msg dados={r.erros} />)
            //toast.update(id, { render: <Msg dados={r.erros} />, type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_CENTER, closeButton: true });
        }
    }
    useEffect(() => {
    }, [])

    return (
        <>
            <Dados setDados={setDados} dados={dados} />
            <Contatos setDados={setDados} dados={dados} />
            <Endereco setDados={setDados} dados={dados} />
            <Card className="shadow">
                <Card.Footer>
                    <Button onClick={saveNew} size={'sm'} className="float-end"><FaSave className='mb-1' /> Gravar</Button>
                </Card.Footer>
            </Card>
            {notification ? (<ModalNotifications show={notification} close={setNotification} texto={txt} message={msg} />) : null}
        </>
    )
}
export default CadastrosMembrosNew
