import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import { FaHouseUser, FaSearch, FaSignOutAlt } from 'react-icons/fa'
import { ImLocation } from 'react-icons/im'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import AuxiliaresBairros from '../../../../components/auxiliares/bairros'
import AuxiliaresCidades from '../../../../components/auxiliares/cidades'
import Modals from '../../../../components/modals'
import { RequestGet } from '../../../../requestApi'

const Endereco = (props) => {
    const [erCep, setErCep] = useState("")
    const [loading, setLoading] = useState(false);
    //const [cep, setCep] = useState(props.dados.cep)
    //const [cidade, setCidade] = useState(props.dados.cidade ? props.dados.cidade : "")
    //const [uf, setUf] = useState(props.dados.uf ? props.dados.uf : "")
    //const [endereco, setEndereco] = useState(props.dados.endereco)
    //const [compl, setCompl] = useState(props.dados.compl)
    //const [bairro, setBairro] = useState(props.dados.bairro)
    //const [regiao, setRegiao] = useState(props.dados.regiao)
    //const [idCidade, setIdCidade] = useState(props.dados.idcidade)
    //const [idBairro, setIdBairro] = useState(props.dados.idbairro)
    const [openCidade, setOpenCidade] = useState(false)
    const [getCidade, setGetCidade] = useState({ id: props.dados.idCidade, cidade: props.dados.cidade, uf: props.dados.uf })
    const [getBairro, setGetBairro] = useState({ id: props.dados.idBairro, bairro: props.dados.bairro, regiao: props.dados.regiao })
    const [openBairro, setOpenBairro] = useState(false)

    const getData = (e) => {
        const { name, value } = e.target;
        props.setDados({ ...props.dados, [name]: value });
    };

    const getCep = async () => {
        setLoading(true);
        setErCep("")

        if (props.dados.cep.length > 0) {

            const id = toast.loading(" Buscando CEP...")

            const r = await RequestGet("/FindLocation/Cep/" + props.dados.cep)

            if (r.status === 1) {
                props.setDados({ ...props.dados, endereco: r.endereco, idBairro: r.idbairro, bairro: r.bairro, idCidade: r.idcidade, cidade: r.cidade, uf: r.uf, regiao: r.regiao })
                // setEndereco(r.endereco)
                // setBairro(r.bairro)
                // setCidade(r.cidade)
                // setUf(r.uf)
                // setIdCidade(r.idcidade)
                // setIdBairro(r.idbairro)
                // setRegiao(r.regiao)
                toast.dismiss()
            }
            else if (r.status === 2) {
                props.setDados({ ...props.dados, endereco: r.endereco, idBairro: null, bairro: r.bairro, idCidade: r.idcidade, cidade: r.cidade, uf: r.uf, regiao: r.regiao })
                toast.dismiss()
            }
            else {
                setErCep(r.message)
                toast.update(id, { render: r.message, type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_RIGHT, closeButton: true });
            }
        }
        else
            setErCep("CEP inválido")

        setLoading(false);
    }

    const getLocation = async () => {
        const id = toast.loading(" Buscando GeoLocalização...")

        var endrc = props.dados.endereco ? props.dados.endereco + "," : "";
        endrc += props.dados.num ? props.dados.num + "," : "";
        endrc += props.dados.bairro ? props.dados.bairro + "," : "";
        endrc += props.dados.cidade ? props.dados.cidade + "," : "";
        endrc += props.dados.uf ? props.dados.uf + "," : "";

        console.log(endrc)

        const r = await RequestGet("/FindLocation/Map?address=" + endrc)
        console.log(r)

        if (r.status === 1) {
            props.setDados({ ...props.dados, latitude: r.lat, longitude: r.lng })
            toast.dismiss();
        }
        else {
            toast.update(id, { render: r.message, type: "error", isLoading: false, autoClose: 15000, closeButton: true });
        }
    }

    useEffect(() => {
        if (getCidade.id !== props.dados.idCidade) {
            props.setDados({ ...props.dados, idCidade: getCidade.id, cidade: getCidade.cidade, uf: getCidade.uf, idBairro: null, bairro: "", regiao: "" })
            console.log(getCidade)
        }
        else
            props.setDados({ ...props.dados, idCidade: getCidade.id, cidade: getCidade.cidade, uf: getCidade.uf })
    }, [getCidade])

    useEffect(() => {
        props.setDados({ ...props.dados, idBairro: getBairro.id, bairro: getBairro.bairro, regiao: getBairro.regiao })
    }, [getBairro])

    return (
        <>
            <Card className='mb-2 shadow'>
                <Card.Header><ImLocation /> Endereço</Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col lg={2} md={4}>
                            <Form.Group controlId="cep">
                                <Form.Label className="mb-0">CEP</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        // as={ReactInputMask}
                                        type="text"
                                        size="sm"
                                        style={{ textTransform: "uppercase" }}
                                        name="cep"
                                        onChange={(e) => { getData(e) }}
                                        value={props.dados.cep ? props.dados.cep : ""}
                                    />
                                    <Button
                                        variant="light"
                                        size="sm"
                                        onClick={() => getCep()}
                                        className="border"
                                        disabled={loading}
                                    >
                                        {/* {loading ? <Spinner size="sm" className="float-start p-2" /> : <FaSearch color="#babbbc" />} */}
                                        <FaSearch color="#babbbc" />

                                    </Button><Form.Text className="text-muted">
                                        <span className='text-red'>{loading ? <Spinner size="sm" className="float-start p-2" /> : ""}</span>
                                    </Form.Text>
                                </InputGroup>
                                <Form.Text className="text-muted">
                                    <span className="text-danger">{erCep}</span>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col lg={5} md={8}>
                            <Form.Group controlId="endereco">
                                <Form.Label className="mb-0">Endereço</Form.Label>
                                <Form.Control type="text" size="sm" maxLength={50} style={{ textTransform: "uppercase" }} name="endereco" onChange={getData} value={props.dados.endereco ? props.dados.endereco : ""} />
                            </Form.Group>
                        </Col>
                        <Col lg={1} md={4}>
                            <Form.Group controlId="num">
                                <Form.Label className="mb-0">Nº</Form.Label>
                                <Form.Control type="text" size="sm" style={{ textTransform: "uppercase" }} maxLength={10} name="num" onChange={getData} value={props.dados.num ? props.dados.num : ""} />
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={8}>
                            <Form.Group controlId="compl">
                                <Form.Label className="mb-0">Complemento</Form.Label>
                                <Form.Control type="text" size="sm" style={{ textTransform: "uppercase" }} maxLength={40} name="compl" onChange={getData} value={props.dados.compl ? props.dados.compl : ""} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="pb-2">
                        <Col lg={6} md={12}>
                            <Form.Group controlId="cidade">
                                <Form.Label className="mb-0">Cidade</Form.Label>
                                <InputGroup>
                                    <Form.Control type="text" size="sm" name="cidade" value={props.dados.cidade ? props.dados.cidade + " / " + props.dados.uf : ""} style={{ textTransform: "uppercase" }} disabled />

                                    <Button
                                        variant="light"
                                        size="sm"
                                        onClick={() => setOpenCidade(true)}
                                        className="border"
                                        disabled={loading}
                                    >
                                        {/* {loading ? <Spinner size="sm" className="float-start p-2" /> : <FaSearch color="#babbbc" />} */}
                                        <FaSearch color="#babbbc" />

                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col lg={4} md={8}>
                            <Form.Group controlId="bairro">
                                <Form.Label className="mb-0">Bairro</Form.Label>
                                <InputGroup>
                                    <Form.Control type="text" size="sm" maxLength={40} style={{ textTransform: "uppercase" }} onChange={getData} name="bairro" value={props.dados.bairro ? props.dados.bairro : ""} disabled />
                                    <Button variant='light' size='sm' onClick={() => setOpenBairro(true)} className="border" disabled={loading}><FaSearch color='#babbbc' /></Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col lg={2} md={4}>
                            <Form.Group controlId="estado">
                                <Form.Label className="mb-0">Região</Form.Label>
                                <Form.Control type="text" size="sm" maxLength={40} style={{ textTransform: "uppercase" }} name="regiao" value={props.dados.regiao ? props.dados.regiao : ""} disabled />
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <Row className="pb-2">
                        <Col sm={6}>
                            <Form.Group controlId="cidade">
                                <Form.Label className="mb-0">Localização</Form.Label>
                                <InputGroup>

                                    <Button
                                        variant="light"
                                        size="sm"
                                        onClick={() => getLocation()}
                                        className="border"
                                        disabled={loading}
                                    >
                                        <FaSearch color="#babbbc" />

                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row> */}
                </Card.Body>
            </Card>
            {openCidade ? (<Modals show={openCidade} close={setOpenCidade} icon={<FaHouseUser />} title="Cidade" footer={<Button variant="secondary" size="sm" onClick={() => setOpenCidade(false)}><FaSignOutAlt />Sair</Button>}><AuxiliaresCidades uf={props.dados.uf ? props.dados.uf : "N"} dados={getCidade} setDados={setGetCidade} close={setOpenCidade} /></Modals>) : null}
            {openBairro ? (<Modals show={openBairro} close={setOpenBairro} icon={<FaHouseUser />} title="Bairro" footer={<Button variant="secondary" size="sm" onClick={() => setOpenBairro(false)}><FaSignOutAlt />Sair</Button>}><AuxiliaresBairros idCidade={props.dados.idCidade} dados={getBairro} setDados={setGetBairro} close={setOpenBairro} /></Modals>) : null}
        </>
    )
}

export default Endereco
