import React from "react";
import Bread from "react-bootstrap/Breadcrumb";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const Breadcrumb = ({ icon, title, links = [] }) => {
    return (
        <>
            <Bread className="mt-3 mb-0">
                <Bread.Item linkAs={Link} linkProps={{ to: "/" }}><FaHome /></Bread.Item>
                {links.map((d, i) => (
                    <Bread.Item linkAs={Link} linkProps={{ to: d.link }} active={d.ativo} key={i}>{d.nome}</Bread.Item>
                ))}
            </Bread>
            <ul className="nav" style={{ marginTop: -5 }}>
                <li className="nav-item text-primary nav-link ps-1">
                    <h4>
                        {icon} <strong>{title}</strong>
                    </h4>
                </li>
            </ul>
        </>
    );
};

export default Breadcrumb;