import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { Link, Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import estados from '../../assets/estados.json'
import { RequestGet } from '../../requestApi'
import Msg from '../errors/msg'

const AuxiliaresCidades = (props) => {
    const [uf, setUf] = useState(props.uf ? props.uf : "N")
    const [cidades, setCidades] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const loadDados = async () => {
            toast.dismiss()
            setLoading(true);

            const id = toast.loading(" Carregando cidades...")

            const r = await RequestGet("/Tabelas/Cidades/Estado/" + uf)

            if (r.status === 1) {
                setCidades(r.cidades)
                toast.dismiss()
            }
            else if (r.status === 0) {
                setUf("N");
                setCidades([])
                toast.update(id, { render: <Msg dados={r.erros} />, type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_RIGHT, closeButton: true });
            }
            else {
                toast.update(id, { render: <Msg dados={r.erros} />, type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_RIGHT, closeButton: true });
                setUf("N");
            }
            setLoading(false);
        }
        if (uf !== "N")
            loadDados()
    }, [uf])
    const [busca, setBusca] = useState(props.dados.cidade ? props.dados.cidade : "");
    const handleGetCity = (id, cidade) => {
        props.setDados({ id: id, cidade: cidade?.toUpperCase(), uf: uf?.toUpperCase() })
        props.close(false)
    }
    return (
        <>
            <Row className="mb-0">
                <Col>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Estado <span className="text-danger">*</span></Form.Label>
                        <Form.Select size="sm" name="idNacionalidade" onChange={(e) => setUf(e.target.value)} value={uf} autoFocus={uf === "N" ? true : false}>
                            <option disabled value="N" className="text-muted">-- Selecione o Estado --</option>
                            {estados.map((e, i) => (<option key={i} value={e.Id} >{e.Nome.toUpperCase()}</option>))}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Buscar Cidade </Form.Label>
                        <Form.Control type="text" size="sm" style={{ textTransform: "uppercase" }} onChange={(e) => setBusca(e.target.value)} value={busca} autoFocus={uf !== "N" ? true : false} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{ height: 375, overflowX: 'scroll' }}>
                        {loading ? <Spinner animation="border" size="sm" className="float-start mt-2" title="Carregando cidades" /> :
                            <Table striped hover size="sm">
                                <tbody>
                                    {cidades.filter(item => item.nome.includes(busca?.toUpperCase())).map((c, i) => (
                                        <tr key={i}>
                                            <td className="ps-2">{c.nome}</td>
                                            <td><Link onClick={() => handleGetCity(c.id, c.nome)}><FaCheck color="#003768" /></Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default AuxiliaresCidades
