import React from 'react'

const Msg = ({ dados }) => (
    <div>
        {dados?.map((m, i) => (
            <span key={i}>{m}<br /></span>
        ))}
    </div>
)

export default Msg
