import React, { useState } from 'react'
import { Row, Form, Spinner, Table, Col, InputGroup, Button } from 'react-bootstrap'
import { FaCheck, FaHouseUser, FaSearch, FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CadastrosFamiliasEditMembros from '../../pages/cadastros/familias/editmembros';
import { RequestGet } from '../../requestApi';
import Modals from '../modals';
import ModalLoading from '../modals/loading';

const Msg = ({ dados }) => (
    <div>
        {dados.map((m, i) => (
            <span key={i}>{m}<br /></span>
        ))}
    </div>
)

const AuxiliaresMembros = (props) => {
    const [membros, setMembros] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [ldng, setLdng] = useState(false)
    const [dadosMembro, setDadosMembro] = useState({})
    const [openEdit, setOpenEdit] = useState(false)

    const loadDados = async () => {
        toast.dismiss()
        setLoading(true);

        const id = toast.loading(" Carregando Membros...")

        const r = await RequestGet("/Cadastros/Membros/Chefes/List?Search=" + search)

        if (r.status === 1) {
            setMembros(r.data.membros)
            toast.dismiss()
        }
        else {
            toast.update(id, { render: r.errors[0], type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_RIGHT, closeButton: true });
            //toast.error(r.erros[0])
        }
        setLoading(false);
    }

    const handleSearch = (e) => {
        //e.preventDefault();
        if (e.key === "Enter") {
            loadDados();
        }
    };

    const handleChefe = () => {
        props.handleChefe(dadosMembro.id, dadosMembro.nome, dadosMembro.conhecidoComo, dadosMembro.latitude, dadosMembro.longitude)
        props.close(false)
        setOpenEdit(false)
    }
    const handleDep = () => {
        props.handleDep(dadosMembro.id, dadosMembro.nome, dadosMembro.conhecidoComo, dadosMembro.idGrauParentesco, dadosMembro.parentesco)
        props.close(false)
        setOpenEdit(false)
    }

    const loadMembro = async (ids) => {
        setLdng(true)
        //toast.dismiss()

        const id = toast.loading(" Carregando Dados...")

        const r = await RequestGet("/Cadastros/Membros/View/" + ids)

        if (r.status === 1) {
            setDadosMembro(r.data)
            setLdng(false)
            setOpenEdit(true)
            toast.update(id, { render: r.message, type: "success", isLoading: false, autoClose: 100, closeButton: true });
            console.log(r.data)
        }
        else if (r.status === 3) {
            window.location.reload();
        }
        else {
            toast.update(id, { render: <Msg dados={r.erros} />, type: "error", isLoading: false, autoClose: 15000, closeButton: true });
        }
    }

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Membro</Form.Label>
                        <InputGroup >
                            <Form.Control type="text" size="sm" style={{ textTransform: "uppercase" }} onKeyDown={handleSearch} onChange={(e) => setSearch(e.target.value.toUpperCase())} defaultValue={search} autoFocus={true} />
                            <Button variant="primary" size="sm" onClick={loadDados} >
                                <FaSearch />
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{ height: 375, overflowX: 'scroll' }}>
                        {loading ? <Spinner animation="border" size="sm" className="float-start mt-2" title="Carregando membros" /> :
                            <Table striped hover size="sm">
                                <tbody>
                                    {membros.map((m, i) => (
                                        <tr key={i}>
                                            <td className="ps-2">{m.nome}</td>
                                            <td>{m.apelido}</td>
                                            <td><Link onClick={() => loadMembro(m.id)}><FaCheck color="#003768" /></Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        }
                    </div>
                </Col>
            </Row>
            {ldng ? <ModalLoading show={ldng} /> : null}
            {openEdit ? <Modals show={openEdit} close={setOpenEdit} icon={<FaHouseUser />} title="Editar Dados de Membro" size='xl' footer={<Button variant="secondary" size="sm" onClick={() => setOpenEdit(false)}><FaSignOutAlt />Sair</Button>}><CadastrosFamiliasEditMembros dados={dadosMembro} setDados={setDadosMembro} handleChefe={handleChefe} handleDep={handleDep} tipo={props.tipo} /></Modals> : null}
        </>
    )
}

export default AuxiliaresMembros
