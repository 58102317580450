import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

const ModalLoading = (props) => {
    const [idx, setIdx] = useState(1060);

    const closeModal = () => props.close(false);

    useEffect(() => {
        const getIndex = () => {
            const bdp = document.querySelectorAll(".fade.modal-backdrop.show");

            var totl = 1060 + 10 * bdp.length;

            const element = document.getElementsByClassName(
                "fade modal-backdrop show"
            );

            setTimeout(() => {
                element[bdp.length].style["z-index"] = totl - 1;
            }, -1000);

            setIdx(totl)
        }
        getIndex();
    }, [])

    return (
        <>
            <Modal
                show={props.show}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                size="md"
                style={{ zIndex: idx }}
            >
            </Modal>
        </>
    );
};
export default ModalLoading;