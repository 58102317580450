import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { RequestGet } from '../../../requestApi'
import MapGoogle from '../../../components/maps/mapgoogle';
import MapMarkers from '../../../components/maps/mapmarker';

const CadastrosFamiliasView = (props) => {
    const [loading, setLoading] = useState(false)
    const [familia, setFamilia] = useState({});

    const loadDados = async () => {
        toast.dismiss()
        setLoading(true);

        const id = toast.loading(" Carregando Família...")

        const r = await RequestGet("/Cadastros/Familias/Get/" + props.id)

        if (r.status === 1) {
            setFamilia(r.data)
            toast.dismiss()
            setLoading(false);
        }
        else {
            toast.update(id, { render: r.errors[0], type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_RIGHT, closeButton: true });
            //toast.error(r.erros[0])
        }
    }
    useEffect(() => {
        loadDados()
    }, [])

    return (
        <>
            {loading ? <Spinner size="sm" /> :
                <>
                    <Row className='mb-2'>
                        <Col sm={12}>
                            <>
                                <div className="text-gray-500">Nome da Familia</div>
                                <div className="float-start">{familia.nome}</div>
                            </>
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col sm={12}>
                            <div className="text-gray-500">Membros</div>
                            <Table striped hover size="sm">
                                <tbody>
                                    {familia?.membros?.map((m, i) => (
                                        <tr key={i}>
                                            <td className="ps-2">{m.nome}</td>
                                            <td>{m.grauParentesco}</td>
                                            {/* <td><Link onClick={() => loadMembro(m.id)}><FaCheck color="#003768" /></Link></td> */}
                                        </tr>
                                    ))}
                                    {familia?.parentes?.map((m, i) => (
                                        <tr key={i}>
                                            <td className="ps-2">{m.nome}</td>
                                            <td>{m.grauParentesco}</td>
                                            {/* <td><Link onClick={() => loadMembro(m.id)}><FaCheck color="#003768" /></Link></td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col sm={12} style={{ minHeight: 450 }}>
                            <div className="text-gray-500">Localização</div>
                            <div>
                                <MapGoogle positionInitial={{ lat: familia.latitudeIgreja, lng: familia.longitudeIgreja }} >
                                    <MapMarkers position={{ lat: familia.latitudeIgreja, lng: familia.longitudeIgreja }} nome={"IASD " + familia.nomeIgreja} id={-1} icon="https://gi7.online/icons/logo-iasd50.png" />
                                    <MapMarkers position={{ lat: familia.latitude, lng: familia.longitude }} nome={"Familia: " + familia.nome} id={0} chefeFamilia={familia.chefeFamilia} distancia={familia.distancia} />
                                </MapGoogle>
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

export default CadastrosFamiliasView