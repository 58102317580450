import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { ImLocation } from 'react-icons/im'
import { toast } from 'react-toastify'
import Msg from '../../../components/errors/msg'
import ModalNotifications from '../../../components/modals/notifications'
import { RequestGet, RequestPost } from '../../../requestApi'
import Contatos from '../membros/cards/contatos'
import Dados from '../membros/cards/dados'
import Endereco from '../membros/cards/endereco'

const CadastrosFamiliasEditMembros = (props) => {
    const [parentescos, setParentescos] = useState([])
    const [parente, setParente] = useState("");

    //Notificacato
    const [notification, setNotification] = useState(false)
    const [txt, setTxt] = useState(null);
    const [msg, setMsg] = useState(null);

    const getData = (e) => {
        const { name, value } = e.target;
        //setParente(e.target.options[e.target.selectedIndex].text)
        props.setDados({ ...props.dados, [name]: value, parentesco: e.target.options[e.target.selectedIndex].text });
    };

    const saveEdit = async () => {
        setNotification(true)
        setTxt("Enviando Dados");
        setMsg(null)
        //To int os ids necessarios
        // dados.idEstadoCivil = parseInt(dados.idEstadoCivil)
        // dados.idRaca = parseInt(dados.idRaca)
        // dados.idNacionalidade = parseInt(dados.idNacionalidade)
        // dados.idEscolaridade = parseInt(dados.idEscolaridade)
        // dados.idSituacaoProfissional = parseInt(dados.idSituacaoProfissional)
        // dados.idSituacaoMoradia = parseInt(dados.idSituacaoMoradia)
        // dados.idNecessidadeEspecial = parseInt(dados.idNecessidadeEspecial)
        props.dados.idGrauParentesco = parseInt(props.dados.idGrauParentesco)


        //const id = toast.loading(" Enviando dados...")

        if (props.tipo === 2) {
            if (!props.dados.idGrauParentesco) {
                setMsg("Grau de Parentesco deve ser informado");
                return
            }

        }
        console.log(props.dados)

        const r = await RequestPost("/Cadastros/Membros/Update/" + props.dados.id, props.dados)

        if (r.status === 1) {
            toast.success(r.message)
            //props.setDados(dados)
            if (props.tipo === 1)
                props.handleChefe();
            else if (props.tipo === 2)
                props.handleDep();

            setNotification(false)
        }
        else {
            setMsg(<Msg dados={r.errors} />)
            //toast.update(id, { render: <Msg dados={r.erros} />, type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_CENTER, closeButton: true });
        }
    }
    useEffect(() => {
        const loadParentescos = async () => {
            const id = toast.loading(" Carregando Tabelas...")
            const r = await RequestGet("/Tabelas/GrauParentescos/List")

            if (r.status === 1) {
                setParentescos(r.data)
                toast.dismiss()
            }
            else {
                toast.update(id, { render: <Msg dados={r.errors} />, type: "error", isLoading: false, autoClose: 15000, closeButton: true });
            }
        }
        if (props.tipo === 2) {
            loadParentescos();
        }
    }, [])

    return (
        <>
            <Dados setDados={props.setDados} dados={props.dados} footer="no" />
            <Contatos setDados={props.setDados} dados={props.dados} footer="no" />
            {props.tipo === 1 &&
                <Endereco setDados={props.setDados} dados={props.dados} />
            }
            {props.tipo === 2 &&
                <Card className='mb-2 shadow'>
                    <Card.Header><ImLocation /> Parentesco do Chefe de Família</Card.Header>
                    <Card.Body>
                        <Row className="pb-2">
                            <Col sm={4}>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Grau de Parentesco <span className="text-danger">*</span></Form.Label>
                                    <Form.Select size="sm" name="idGrauParentesco" onChange={getData} value={props.dados.idGrauParentesco ? props.dados.idGrauParentesco : 0}>
                                        <option disabled value={0} className="text-muted">-- Selecione --</option>
                                        {parentescos.map((p, i) => (<option key={i} value={p.id} >{p.nome.toUpperCase()}</option>))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
            <Card className="shadow">
                <Card.Body>
                    <Button onClick={saveEdit} size={'sm'} className="float-end">Gravar</Button>
                </Card.Body>
            </Card>
            {notification ? (
                <ModalNotifications
                    show={notification}
                    close={setNotification}
                    texto={txt}
                    message={msg}
                />
            ) : null}
        </>
    )
}

export default CadastrosFamiliasEditMembros