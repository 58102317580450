import React, { useEffect, useState } from 'react'
import { Row, Form, Spinner, Table, Col, InputGroup, Button } from 'react-bootstrap'
import { FaCheck, FaHouseUser, FaPlus, FaSearch, FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CadastrosFamiliasEditMembros from '../../pages/cadastros/familias/editmembros';
import CadastrosMembrosNewParente from '../../pages/cadastros/membros/newparente';
import { RequestGet } from '../../requestApi';
import Modals from '../modals';
import ModalLoading from '../modals/loading';

const Msg = ({ dados }) => (
    <div>
        {dados.map((m, i) => (
            <span key={i}>{m}<br /></span>
        ))}
    </div>
)

const AuxiliaresMembrosNaoBatizados = (props) => {
    const [membros, setMembros] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openNewParente, setOpenNewParente] = useState(false)
    const [ldng, setLdng] = useState(false)
    const [dadosMembro, setDadosMembro] = useState({})

    const handleDepNb = () => {
        props.handleDepNb(dadosMembro.id, dadosMembro.nome, dadosMembro.conhecidoComo, dadosMembro.idGrauParentesco, dadosMembro.parentesco)
        props.close(false)
        setOpenNewParente(false)
    }

    const getParente = async (id, nome, idGrauParentesco, parentesco) => {
        props.handleDepNb(id, nome, nome, idGrauParentesco, parentesco)
        props.close(false)
        setOpenNewParente(false)
    }

    useEffect(() => {
        const loadDados = async () => {
            toast.dismiss()
            setLoading(true);

            const id = toast.loading(" Carregando Parentes...")

            const r = await RequestGet("/Cadastros/Membros/NaoBatizados/List/" + props.idchefe)

            if (r.status === 1) {
                setMembros(r.data.parentes)
                toast.dismiss()
            }
            else {
                toast.update(id, { render: r.errors[0], type: "error", isLoading: false, autoClose: 15000, position: toast.POSITION.TOP_RIGHT, closeButton: true });
                //toast.error(r.erros[0])
            }
            setLoading(false);
        }
        loadDados()
    }, [])

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Parente não batizado</Form.Label>
                        <InputGroup >
                            <Button variant="primary" size="sm" onClick={() => setOpenNewParente(true)}>
                                <FaPlus className='mb-1' /> Adicionar Parente não Batizado
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{ height: 375, overflowX: 'scroll' }}>
                        {loading ? <Spinner animation="border" size="sm" className="float-start mt-2" title="Carregando membros" /> :
                            <Table striped hover size="sm">
                                <tbody>
                                    {membros.map((m, i) => (
                                        <tr key={i}>
                                            <td className="ps-2">{m.nome}</td>
                                            <td>{m.parentesco}</td>
                                            <td>{m.grupoFamiliar ? "" : <Link onClick={() => getParente(m.id, m.nome, m.idGrauParentesco, m.parentesco)}><FaCheck color="#003768" /></Link>}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        }
                    </div>
                </Col>
            </Row>
            {ldng ? <ModalLoading show={ldng} /> : null}
            {openNewParente ? <Modals show={openNewParente} close={setOpenNewParente} icon={<FaHouseUser />} title="Parente não Batizado" size='xl'><CadastrosMembrosNewParente dados={dadosMembro} setDados={setDadosMembro} handleDepNb={handleDepNb} idchefe={props.idchefe} /></Modals> : null}
        </>
    )
}

export default AuxiliaresMembrosNaoBatizados
