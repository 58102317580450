import { GoogleMap, TransitLayer, useJsApiLoader } from '@react-google-maps/api'
import React, { useState } from 'react'

const options = {
    mapId: process.env.REACT_APP_GOOGLE_MAPS_API_ID,
    //disableDefaultUI: true,
    clickableIcons: false
}

const MapGoogle = ({ children, positionInitial, width = '100%', height = '400px', viewUi = true }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        language: "pt-br"
    })
    return (
        <>
            <div>
                {isLoaded ? (
                    <GoogleMap
                        id='marker-example'
                        mapContainerStyle={{ width: width, height: height }}
                        center={positionInitial}
                        zoom={13}
                        options={{ ...options, disableDefaultUI: viewUi }}
                    >
                        <TransitLayer />
                        {children}
                    </GoogleMap>
                ) : <>Erro carregamento do mapa</>
                }
            </div>
        </>
    )
}

export default MapGoogle
