import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Authorization from './Authorization';
import { Home, Login, NotPermit, NotFound, LoginOut, Cadastros, CadastrosMembros, CadastrosMembrosNew, CadastrosMembrosView, CadastrosFamilias, CadastrosFamiliasNew, CadastrosFamiliasView, Usuarios } from './pages';
import Adm from './pages/testes/adm';
import Usuario from './pages/testes/usuario';
import Super from './pages/testes/super'
import { Sidebar, Header, Menu } from './components';
import { Container } from 'react-bootstrap';
import { useStateContext } from './contextprovider';
import { toast } from 'react-toastify';
import { DecriptToken } from './functions';

const Private = ({ children }) => {
  const { logado, setLogado } = useStateContext();

  let currentDate = new Date();
  const r = localStorage.getItem("_token")

  const exp = r !== null ? DecriptToken(r).exp : "none";

  if (exp === "none" || exp * 1000 < currentDate.getTime()) {
    localStorage.removeItem("_token")
  }

  !!localStorage.getItem("_token") ? setLogado(true) : setLogado(false)

  return logado ? children : <Navigate to="/login" replace={true} />
}

const App = () => {
  const { logado, setLogado } = useStateContext();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const openSidebar = () => {
    setSidebarOpen(true)
  }

  const closeSidebar = () => { setSidebarOpen(false) }

  useEffect(() => { !!localStorage.getItem("_token") ? setLogado(true) : setLogado(false) }, [])

  return (
    <>
      <div className={"light"}>
        <BrowserRouter>
          <>
            {logado &&
              <>
                <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
                <Header />
              </>
            }
            <Container>
              <Routes>
                <Route path='/' element={<Private><Authorization role={["all"]} ><Home /></Authorization></Private>} />
                <Route path='/cadastros' element={<Private><Authorization role={["administrador", "pastor"]} ><Cadastros /></Authorization></Private>} />
                <Route path='/cadastros/membros' element={<Private><Authorization role={["administrador", "pastor"]} ><CadastrosMembros /></Authorization></Private>} />
                {/* <Route path='/cadastros/membros/new' element={<Private><Authorization role={["administrador", "pastor"]} ><CadastrosMembrosNew /></Authorization></Private>} />
                <Route path='/cadastros/membros/view' element={<Private><Authorization role={["administrador", "pastor"]} ><CadastrosMembrosView /></Authorization></Private>} /> */}
                <Route path='/cadastros/familias' element={<Private><Authorization role={["administrador", "pastor"]} ><CadastrosFamilias /></Authorization></Private>} />
                {/* <Route path='/cadastros/familias/new' element={<Private><Authorization role={["administrador", "pastor"]} ><CadastrosFamiliasNew /></Authorization></Private>} />
                <Route path='/cadastros/familias/view' element={<Private><Authorization role={["administrador", "pastor"]} ><CadastrosFamiliasView /></Authorization></Private>} /> */}
                <Route path='/tabelas' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/bairros' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/doencascronicas' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/escolaridades' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/estadocivil' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/grauparentescos' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/nacionalidades' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/necessidadesespeciais' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/racas' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/regioes' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/religioes' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/situacoesmoradias' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/situacoesprofissionais' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/tabelas/status' element={<Private><Authorization role={["super"]} ><CadastrosFamiliasView /></Authorization></Private>} />
                <Route path='/usuarios' element={<Private><Authorization role={["super"]} ><Usuarios /></Authorization></Private>} />
                <Route path='/perfil' element={<Private><Authorization role={["all"]} ><Usuario /></Authorization></Private>} />
                <Route path='/adm' element={<Private><Authorization role={["administrador", "pastor"]}><Adm /></Authorization></Private>} />
                <Route path='/super' element={<Private><Authorization role={["super"]}><Super /></Authorization></Private>} />
                <Route path='/errors/notpermit' element={<NotPermit />} />
                <Route path='/login' element={<Login />} />
                <Route path='/logout' element={<LoginOut />} />
                <Route path='*' element={(<NotFound />)} />
              </Routes>
            </Container>
          </>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
