import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { FaEye, FaHouseUser, FaPlus, FaSearch, FaSignOutAlt, FaTrash, FaTrashAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import AuxiliaresMembros from '../../../components/auxiliares/membros'
import AuxiliaresMembrosChefes from '../../../components/auxiliares/membros'
import AuxiliaresMembrosNaoBatizados from '../../../components/auxiliares/membrosnb'
import Modals from '../../../components/modals'

const CadastrosFamiliasNew = (props) => {
    const [idChefeFamilia, setIdChefeFamilia] = useState(0)
    const [openChefe, setOpenChefe] = useState(false)
    const [openDep, setOpenDep] = useState(false)
    const [openDepNb, setOpenDepNb] = useState(false)
    const [membros, setMembros] = useState([])
    const [membrosNb, setMembrosNb] = useState([])

    const getData = (e) => {
        const { name, value } = e.target;
        props.setDados({ ...props.dados, [name]: value });
    }

    const handleChefe = (id, nome, apelido, lat, lng) => {
        setIdChefeFamilia(id)
        props.idChefe(id)
        setMembros([{ idMembro: id, idGrauParentesco: 1, nome: apelido ? apelido : nome, parentesco: "CHEFE DE FAMÍLIA" }])
        props.setDados({ ...props.dados, latitude: lat, longitude: lng, membros: [{ idMembro: id, idGrauParentesco: 1, nome: apelido ? apelido : nome, parentesco: "CHEFE DE FAMÍLIA" }] })
    }

    const handleDep = (id, nome, apelido, idgrau, parentesco) => {
        setMembros([...membros, { idMembro: id, idGrauParentesco: idgrau, nome: apelido ? apelido : nome, parentesco: parentesco }])
        props.setDados({ ...props.dados, membros: [...membros, { idMembro: id, idGrauParentesco: idgrau, nome: apelido ? apelido : nome, parentesco: parentesco }] })
    }
    const handleDepNb = (id, nome, apelido, idgrau, parentesco) => {
        setMembrosNb([...membrosNb, { idMembroParente: id, idGrauParentesco: idgrau, nome: apelido ? apelido : nome, parentesco: parentesco }])
        props.setDados({ ...props.dados, membrosParentes: [...membrosNb, { idMembroParente: id, idGrauParentesco: idgrau, nome: apelido ? apelido : nome, parentesco: parentesco }] })
    }

    const removeParentesco = (idsq) => {

        const idxObj = membros.findIndex(object => {
            return object.id === idsq;
        });
        membros.splice(idxObj, 1);
        console.log(membros)
        setMembros([...membros])
        props.setDados({ ...props.dados, membros: [...membros] })

    }

    const removeParentescoNb = (idsq) => {

        const idxObj = membrosNb.findIndex(object => {
            return object.id === idsq;
        });
        membrosNb.splice(idxObj, 1);
        setMembrosNb([...membrosNb])
        props.setDados({ ...props.dados, membrosParentes: [...membrosNb] })

    }

    return (
        <>
            <Row className='mb-2'>
                <Col sm={6}>
                    <Form.Group controlId="cep">
                        <Form.Label className="mb-0">Nome da Família</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" size="sm" style={{ textTransform: "uppercase" }} name="nome" onChange={getData} value={props.dados.nome ? props.dados.nome : ""} />
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            {idChefeFamilia === 0 &&
                <Row className='mb-2'>
                    <Col sm={12}>

                        <>
                            <div className="text-gray-500">Chefe de Familia</div>
                            <div className="float-start"><Button color='primary' size="sm" onClick={() => setOpenChefe(true)} ><FaPlus /> Adicionar</Button></div>
                        </>
                    </Col>
                </Row>
            }
            {idChefeFamilia > 0 &&
                <>
                    <Row>
                        <Col sm={12}>
                            <Table striped hover size="sm">
                                <thead>
                                    <tr>
                                        <th scope="col">Membro</th>
                                        <th scope="col">Parentesco</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {membros.map((f, i) => (
                                        <tr key={i}>
                                            <td className="ps-2">{f.nome?.toUpperCase()}</td>
                                            <td>{f.parentesco}</td>
                                            <td>{f.parentesco === "CHEFE DE FAMÍLIA" ? "" : <Link onClick={() => removeParentesco(i)}><FaTrashAlt color="#003768" /></Link>}</td>
                                        </tr>
                                    ))}
                                    {membrosNb.map((f, i) => (
                                        <tr key={i}>
                                            <td className="ps-2">{f.nome?.toUpperCase()}</td>
                                            <td>{f.parentesco}</td>
                                            <td>{f.parentesco === "CHEFE DE FAMÍLIA" ? "" : <Link onClick={() => removeParentescoNb(i)}><FaTrashAlt color="#003768" /></Link>}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col sm={12}>
                            <Button color='primary' size="sm" onClick={() => setOpenDep(true)} className="me-2" ><FaPlus className='mb-1' /> Parente Batizado</Button>
                            <Button color='primary' size="sm" onClick={() => setOpenDepNb(true)} ><FaPlus className='mb-1' /> Parente não batizado</Button>
                        </Col>
                    </Row>
                </>
            }
            {openChefe ? <Modals show={openChefe} close={setOpenChefe} size="lg" icon={<FaHouseUser />} title="Chefe de Familia" footer={<Button variant="secondary" size="sm" onClick={() => setOpenChefe(false)}><FaSignOutAlt />Sair</Button>}><AuxiliaresMembros handleChefe={handleChefe} close={setOpenChefe} tipo={1} /></Modals> : null}
            {openDep ? <Modals show={openDep} close={setOpenDep} size="lg" icon={<FaHouseUser />} title="Dependente da Família" footer={<Button variant="secondary" size="sm" onClick={() => setOpenDep(false)}><FaSignOutAlt />Sair</Button>}><AuxiliaresMembros handleDep={handleDep} close={setOpenDep} tipo={2} /></Modals> : null}
            {openDepNb ? <Modals show={openDepNb} close={setOpenDepNb} size="lg" icon={<FaHouseUser />} title="Dependente da Família (não batizado)" footer={<Button variant="secondary" size="sm" onClick={() => setOpenDepNb(false)}><FaSignOutAlt />Sair</Button>}><AuxiliaresMembrosNaoBatizados handleDepNb={handleDepNb} close={setOpenDepNb} idchefe={idChefeFamilia} /></Modals> : null}
        </>
    )
}

export default CadastrosFamiliasNew