import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

//style="background: #ebeef4" 
export const ContextProvider = ({ children }) => {
    const [logado, setLogado] = useState(!!localStorage.getItem("_token"));

    return (
        <StateContext.Provider value={{ logado, setLogado }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);