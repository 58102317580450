import { InfoWindow, Marker } from '@react-google-maps/api'
import React, { useState } from 'react'

const MapMarkers = ({ position, preNome, nome, id, chefeFamilia, distancia, icon, membros }) => {
    const [activeMarker, setActiveMarker] = useState(null)
    return (
        <>
            <Marker position={position} options={{ label: { text: preNome ? preNome + " " + nome : nome, className: "map-marker" } }} onClick={() => setActiveMarker(id)} icon={icon ? icon : null} >
                {activeMarker === id ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>
                            {preNome ? preNome + " " : ""}
                            <span style={{ fontSize: 14, marginTop: 10, fontWeight: 'bold' }}>{nome}</span><br />
                            {chefeFamilia ? <><span style={{ fontSize: 12, fontWeight: 'bold', marginTop: 10 }} >{chefeFamilia}</span><br /></> : ""}
                            {distancia ? <><span style={{ fontSize: 11, marginTop: 10 }} >Distancia da Igreja: {distancia}</span><br /></> : ""}
                            {membros ? <>
                                <span style={{ fontSize: 11, marginTop: 10 }} >Membros Total: {membros.total}</span><br />
                                <span style={{ fontSize: 11, marginTop: 10 }} >Membros Frequentes: {membros.frequentes}</span><br />
                                <span style={{ fontSize: 11, marginTop: 10 }} >Membros Não Frequentes: {membros.naoFrequentes}</span><br />
                                <span style={{ fontSize: 11, marginTop: 10 }} >Membros a Resgatar: {membros.aResgatar}</span><br />
                                <span style={{ fontSize: 11, marginTop: 10 }} >Membros a Resgatar: {membros.aTransferir}</span><br />
                                <span style={{ fontSize: 11, marginTop: 10 }} >Famílias: {membros.familias}</span><br />
                            </> : ""}
                        </div>
                    </InfoWindow>
                ) : null}
            </Marker>
        </>
    )
}

export default MapMarkers