import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { GiModernCity } from 'react-icons/gi'
import ModalsBody from './composition/body'
import ModalsFooter from './composition/footer'
import ModalsHeader from './composition/header'


const Modals = (props, { children }) => {
    //console.log(props.show)

    const [idx, setIdx] = useState(1060);

    const closeModal = () => props.close(false);

    useEffect(() => {
        const getIndex = () => {
            const bdp = document.querySelectorAll(".fade.modal-backdrop.show");

            var totl = 1060 + 10 * bdp.length;

            const element = document.getElementsByClassName(
                "fade modal-backdrop show"
            );

            setTimeout(() => {
                element[bdp.length].style["z-index"] = totl - 1;
            }, -1000);

            setIdx(totl)
        }
        getIndex();
    }, [])

    return (
        <>
            <Modal
                show={props.show}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                size={props.size === null || props.size === undefined ? "md" : props.size}
                style={{ zIndex: idx }}
            >
                <ModalsHeader>{props.icon} {props.title}</ModalsHeader>
                <ModalsBody>{props.children}</ModalsBody>
                {props.footer ? <ModalsFooter>{props.footer}</ModalsFooter> : null}
            </Modal>
        </>
    )
}

export default Modals
