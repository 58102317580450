import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";

import { Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { RequestGet } from "../../requestApi";

const CardsCadastrosMenu = () => {
  const [membros, setMembros] = useState(0)
  const [familias, setFamilias] = useState(0)
  const [usuarios, setUsuarios] = useState(0)
  const [interessados, setInteressados] = useState(0)
  const [loading, setLoading] = useState(true);

  async function getDados() {
    toast.dismiss()

    const id = toast.loading(" Carregando dados...")

    const r = await RequestGet("/Cadastros")

    if (r.status === 1) {
      //toast.success(r.message);
      setMembros(r.membros)
      setFamilias(r.familias)
      setUsuarios(r.usuarios)
      setInteressados(r.interessados)
      toast.dismiss()
    }
    else if (r.status === 3) {
      <Navigate to="/login" replace={true} />
    }
    else {
      toast.update(id, { render: r.erros[0], type: "error", isLoading: false, autoClose: false, position: toast.POSITION.TOP_RIGHT, closeButton: true });
      //toast.error(r.erros[0])
    }
    setLoading(false);

  }

  useEffect(() => {
    getDados();
  }, []);

  return (
    <>
      <Row>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card shadow bg-light">
            <div className="card-body pb-0">
              <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
                <strong>Membros</strong>
              </h6>
              <span className="card-text fs-3 float-end">
                {loading ? <Spinner /> : membros}
              </span>
            </div>
            <div className="card-footer text-center bg-light bg-opacity-75">
              <Link
                to="/cadastros/membros"
                title="Acordos"
                className="text-primary"
              >
                Acessar{" "}
                <FaArrowAltCircleRight size={18} className="text-primary" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card shadow bg-light">
            <div className="card-body pb-0">
              <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
                <strong>Famílias</strong>
              </h6>
              <span className="card-text fs-3 float-end">
                {loading ? <Spinner /> : familias}
              </span>
            </div>
            <div className="card-footer text-center bg-light bg-opacity-75">
              <Link
                to="/cadastros/familias"
                title="Acordos"
                className="text-primary"
              >
                Acessar{" "}
                <FaArrowAltCircleRight size={18} className="text-primary" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card shadow bg-light">
            <div className="card-body pb-0">
              <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
                <strong>Usuários</strong>
              </h6>
              <span className="card-text fs-3 float-end">
                {loading ? <Spinner /> : usuarios}
              </span>
            </div>
            <div className="card-footer text-center bg-light bg-opacity-75">
              <Link
                to="/cadastros/usuarios"
                title="Acordos"
                className="text-primary"
              >
                Acessar{" "}
                <FaArrowAltCircleRight size={18} className="text-primary" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card shadow bg-light">
            <div className="card-body pb-0">
              <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
                <strong>Interessados</strong>
              </h6>
              <span className="card-text fs-3 float-end">
                {loading ? <Spinner /> : interessados}
              </span>
            </div>
            <div className="card-footer text-center bg-light bg-opacity-75">
              <Link
                to="/cadastros/interessados"
                title="Acordos"
                className="text-primary"
              >
                Acessar{" "}
                <FaArrowAltCircleRight size={18} className="text-primary" />
              </Link>
            </div>
          </div>
        </div>
      </Row>
    </>
  )
}

export default CardsCadastrosMenu
