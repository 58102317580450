import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb } from '../../components'

const Home = () => {
    return (
        <>
            <Breadcrumb title="Dashboard" />
            <Link to="/cadastros/membros" >Cadastros de Membros</Link>
        </>
    )
}

export default Home
