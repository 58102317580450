import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { FaChurch } from 'react-icons/fa';
import { toast } from 'react-toastify';
import MapGoogle from '../../../components/maps/mapgoogle';
import MapMarkers from '../../../components/maps/mapmarker';
import { RequestGet } from '../../../requestApi';

const CadastrosFamiliasMap = () => {
    const [familias, setFamilias] = useState([])
    const [msgErro, setMsgErro] = useState("");
    const [sending, setSending] = useState(false);
    const [search, setSearch] = useState("");

    const loadDados = async () => {
        setMsgErro("");

        setSending(true);

        const r = await RequestGet("/Cadastros/Familias/MapList")

        if (r.status === 1) {
            setFamilias(r.data)
            if (r.data.length === 0) {
                setMsgErro(r.message);
            }
        }
        else if (r.status === 3) {
            window.location.reload()
        }
        else {
            setMsgErro(r.message);
            toast.error(r.errors[0])
        }
        setSending(false);
    }
    useEffect(() => {
        loadDados()
    }, [])

    const Iasd = (nome) => {
        <span style={{ fontWeight: 'bold' }}>{nome}</span>
    }

    return (
        <>
            {sending ? <Spinner size='sm' /> :
                <MapGoogle positionInitial={{ lat: familias.latitude, lng: familias.longitude }} height='80vh' viewUi={false} >
                    <MapMarkers position={{ lat: familias.latitude, lng: familias.longitude }} preNome="IASD" nome={familias.igreja} id={-1} membros={{ total: familias.total, frequentes: familias.frequentes, naoFrequentes: familias.frequentesNao, aResgatar: familias.resgatar, aTransferir: familias.transferir, familias: familias.familias?.length }} icon="https://gi7.online/icons/logo-iasd50.png" />
                    {familias?.familias?.map((f, i) =>
                        <MapMarkers position={{ lat: f.latitude, lng: f.longitude }} preNome="Família:" nome={f.nome} id={i} chefeFamilia={f.chefeFamilia} distancia={f.distancia} key={i} />
                    )}
                </MapGoogle>
            }
        </>
    )
}

// function Map(props) {
//     const [activeMarker, setActiveMarker] = useState(null)
// //<MapGoogle positionInitial={{ lat: familia.latitudeIgreja, lng: familia.longitudeIgreja }} >
//     return <GoogleMap zoom={10} center={positionIgreja} position={positionIgreja} mapContainerClassName="map-container" options={options}>
//         <Marker key={-1} position={positionIgreja} options={{ label: { text: "IASD CENTRAL ", className: "markercss", fontWeight: 'bold' } }} onClick={() => setActiveMarker(-1)} icon="https://gi7.online/icons/logo-iasd50.png">
//             {activeMarker === -1 ? (
//                 <InfoWindow onCloseClick={() => setActiveMarker(null)}>
//                     <div><b>IASD CENTAL SJC</b><br /><span style={{ fontSize: 10, fontWeight: 'bold', marginTop: 10 }} >Endereço aqui</span></div>
//                 </InfoWindow>
//             ) : null}
//         </Marker>
//         {props.familias?.map((f, i) => (
//             <Marker key={i} position={{ lat: f.latitude, lng: f.longitude }} options={{ label: { text: "Familia: " + f.nome, className: "map-marker", fontWeight: 'bold' } }} onClick={() => setActiveMarker(i)}>
//                 {activeMarker === i ? (
//                     <InfoWindow onCloseClick={() => setActiveMarker(null)}>
//                         <div>
//                             <span style={{ fontSize: 14, fontWeight: 'bold', marginTop: 10 }}><strong>Familia: {f.nome}</strong></span><br />
//                             <span style={{ fontSize: 12, fontWeight: 'bold', marginTop: 10 }} >{f.chefeFamilia}</span><br />
//                             {f.distancia ? <><span style={{ fontSize: 11, marginTop: 10 }} >Distancia da Igreja: {f.distancia}</span><br /></> : ""}
//                         </div>
//                     </InfoWindow>
//                 ) : null}
//             </Marker>
//             // <Marker position={{ lat: 44, lng: -80 }} />
//         ))}

//     </GoogleMap>
// }

export default CadastrosFamiliasMap
