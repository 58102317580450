import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap'
import { BsPersonBoundingBox } from 'react-icons/bs'
import { FaEdit, FaSave, FaSignOutAlt, FaUser } from 'react-icons/fa'
import { ImLocation } from 'react-icons/im'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Msg from '../../../components/errors/msg'
import Modals from '../../../components/modals'
import ModalNotifications from '../../../components/modals/notifications'
import { RequestGet, RequestPost } from '../../../requestApi'
import { toDateBr } from '../../../functions'
import Dados from './cards/dados'
import Endereco from './cards/endereco'
import Contatos from './cards/contatos'

const CadastrosMembrosView = (props) => {
    const [data, setData] = useState({})
    const [dados, setDados] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [editDados, setEditDados] = useState(false)
    const [editAddress, setEditAddress] = useState(false)
    const [editContacts, setEditContacts] = useState(false)

    //Notificacato
    const [notification, setNotification] = useState(false)
    const [txt, setTxt] = useState(null);
    const [msg, setMsg] = useState(null);

    const saveDados = async () => {
        setNotification(true)
        setMsg(null)
        //To int os ids necessarios
        dados.idEstadoCivil = parseInt(dados.idEstadoCivil)
        dados.idRaca = parseInt(dados.idRaca)
        dados.idNacionalidade = parseInt(dados.idNacionalidade)
        dados.idEscolaridade = parseInt(dados.idEscolaridade)
        dados.idSituacaoProfissional = parseInt(dados.idSituacaoProfissional)
        dados.idSituacaoMoradia = parseInt(dados.idSituacaoMoradia)
        dados.idNecessidadeEspecial = parseInt(dados.idNecessidadeEspecial)
        dados.idDoencaCronica = parseInt(dados.idDoencaCronica)
        dados.dtNascimento = dados.dtNascimento === "" ? null : dados.dtNascimento
        dados.dtBatismo = dados.dtBatismo === "" ? null : dados.dtBatismo
        console.log(dados)

        const r = await RequestPost("Cadastros/Membros/Update/Dados/" + props.id, dados)

        if (r.status === 1) {
            setData({ ...data, ...r.data })
            toast.success(r.message);
            setNotification(false);
            setEditDados(false)
        }
        else {
            setMsg(<Msg dados={r.errors} />);
        }

    }

    const saveEndereco = async () => {
        setNotification(true)
        setMsg(null)
        //To int os ids necessarios
        dados.idCidade = parseInt(dados.idCidade)
        dados.idBairro = parseInt(dados.idBairro)
        console.log(dados)

        const r = await RequestPost("Cadastros/Membros/Update/Endereco/" + props.id, dados)

        if (r.status === 1) {
            setData({ ...data, ...r.data })
            toast.success(r.message);
            setNotification(false);
            setEditAddress(false)
        }
        else {
            setMsg(<Msg dados={r.errors} />);
        }

    }

    useEffect(() => {
        const loadDados = async () => {
            setLoading(true)

            const r = await RequestGet("Cadastros/Membros/View/" + props.id)

            if (r.status === 1) {
                setData(r.data)
                setDados(r.data)
                setLoading(false)
            }
            else {
                toast.error(r.errors[0])
                setError(r.errors[0])
            }
        }
        loadDados()
    }, [])

    const BtFooterDados = () => {
        return (
            <>
                <Button variant="secondary" size="sm" onClick={() => setEditDados(false)}><FaSignOutAlt /> Sair</Button>
                <Button variant="primary" size="sm" onClick={saveDados}><FaSave /> Gravar</Button>
            </>
        )
    }
    const BtFooterEndereco = () => {
        return (
            <>
                <Button variant="secondary" size="sm" onClick={() => setEditAddress(false)}><FaSignOutAlt /> Sair</Button>
                <Button variant="primary" size="sm" onClick={saveEndereco}><FaSave /> Gravar</Button>
            </>
        )
    }

    return (
        <>
            {loading ?
                <>
                    {!error ? <><Spinner size='sm' /><span> Carregando dados...</span></> : <span> {error}</span>}
                </>
                :
                <>
                    <Card className='mb-2 shadow'>
                        <Card.Header><BsPersonBoundingBox /> Dados Cadastrais <span className='float-end'><Link onClick={() => { setDados(data); setEditDados(true); }}><FaEdit className='mb-1' /> Editar</Link></span></Card.Header>
                        <Card.Body>
                            <Row className='mb-1'>
                                <Col sm={7}>
                                    <div className="text-gray-400 text-secondary">Nome</div>
                                    <div className="float-start fw-bold">{data.nome}</div>
                                </Col>
                                <Col sm={5} className="border-start">
                                    <div className="text-gray-400 text-secondary">Conhecido(a) Como</div>
                                    <div className="float-start fw-bold">{data.conhecidoComo}&nbsp;</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={5}>
                                    <div className="text-gray-400 text-secondary">Dt. Nascimento</div>
                                    <div className="float-start fw-bold">{toDateBr(data.dtNascimento)}</div>
                                </Col>
                                <Col lg={1} md={2} className="border-start">
                                    <div className="text-gray-400 text-secondary">Idade</div>
                                    <div className="float-start fw-bold">{data.idade}&nbsp;</div>
                                </Col>
                                <Col lg={2} md={5} className="border-start">
                                    <div className="text-gray-400 text-secondary">Dt. Batismo</div>
                                    <div className="float-start fw-bold">{toDateBr(data.dtBatismo)}</div>
                                </Col>
                                <Col lg={2} md={4} className="border-start">
                                    <div className="text-gray-400 text-secondary">Sexo</div>
                                    <div className="float-start fw-bold">{data.sexo === "M" ? "MASCULINO" : "FEMININO"}&nbsp;</div>
                                </Col>
                                <Col lg={2} md={4} className="border-start">
                                    <div className="text-gray-400 text-secondary">Estado Civil</div>
                                    <div className="float-start fw-bold">{data.estadoCivil}&nbsp;</div>
                                </Col>
                                <Col lg={2} md={4} className="border-start">
                                    <div className="text-gray-400 text-secondary">Raça / Cor</div>
                                    <div className="float-start fw-bold">{data.raca}&nbsp;</div>
                                </Col>
                            </Row>
                            <Row className='mb-1'>
                                <Col lg={4} md={6}>
                                    <div className="text-gray-500 text-secondary">Escolaridade</div>
                                    <div className="float-start fw-bold">{data.escolaridade}&nbsp;</div>
                                </Col>
                                <Col lg={4} md={6} className="border-start">
                                    <div className="text-gray-500 text-secondary">Situação Profissional</div>
                                    <div className="float-start fw-bold">{data.situacaoProfissional}&nbsp;</div>
                                </Col>
                                <Col lg={4} md={6} className="border-start">
                                    <div className="text-gray-500 text-secondary">Situação de Moradia</div>
                                    <div className="float-start fw-bold">{data.situacaoMoradia}&nbsp;</div>
                                </Col>
                            </Row>
                            <Row className='mb-1'>
                                <Col lg={4} md={6}>
                                    <div className="text-gray-500 text-secondary">Necessidade Especial</div>
                                    <div className="float-start fw-bold">{data.necessidadeEspecial}&nbsp;</div>
                                </Col>
                                <Col lg={4} md={6} className="border-start">
                                    <div className="text-gray-500 text-secondary">Doença Crônica</div>
                                    <div className="float-start fw-bold">{data.doencaCronica}&nbsp;</div>
                                </Col>
                                <Col lg={4} md={6} className="border-start">
                                    <div className="text-gray-500 text-secondary">Nacionalidade</div>
                                    <div className="float-start fw-bold">{data.nacionalidade}&nbsp;</div>
                                </Col>
                            </Row>
                            <Row className='mb-1'>
                                <Col lg={7} md={12}>
                                    <div className="text-gray-500 text-secondary">Cidade Nascimento</div>
                                    <div className="float-start fw-bold">{data.idCidadeNascimento ? data.cidadeNascimento + " / " + data.ufNascimento : ""}&nbsp;</div>
                                </Col>
                                <Col lg={5} md={12} className="border-start">
                                    <div className="text-gray-400 text-secondary">Status</div>
                                    <div className="float-start fw-bold">{data.status}&nbsp;</div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className='mb-2 shadow'>
                        <Card.Header><ImLocation /> Contatos <span className='float-end'><Link onClick={() => { setDados(data); setEditContacts(true) }}><FaEdit className='mb-1' /> Editar</Link></span></Card.Header>
                        <Card.Body>
                            <Row className='mb-1'>
                                <Col lg={2} md={4}>
                                    <div className="text-gray-400 text-secondary">Telefone</div>
                                    <div className="float-start fw-bold">{data.tel}</div>
                                </Col>
                                <Col lg={6} md={8} className="border-start">
                                    <div className="text-gray-400 text-secondary">E-mail</div>
                                    <div className="float-start fw-bold">{data.email}&nbsp;</div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className='mb-2 shadow'>
                        <Card.Header><ImLocation /> Endereço <span className='float-end'><Link onClick={() => { setDados(data); setEditAddress(true) }}><FaEdit className='mb-1' /> Editar</Link></span></Card.Header>
                        <Card.Body>
                            <Row className='mb-1'>
                                <Col lg={2} md={3}>
                                    <div className="text-gray-400 text-secondary">CEP</div>
                                    <div className="float-start fw-bold">{data.cep}</div>
                                </Col>
                                <Col lg={6} md={9} className="border-start">
                                    <div className="text-gray-400 text-secondary">Endereço</div>
                                    <div className="float-start fw-bold">{data.endereco}&nbsp;</div>
                                </Col>
                                <Col lg={1} md={4} className="border-start">
                                    <div className="text-gray-400 text-secondary">Nº</div>
                                    <div className="float-start fw-bold">{data.num}&nbsp;</div>
                                </Col>
                                <Col lg={3} md={8} className="border-start">
                                    <div className="text-gray-400 text-secondary">Complemento</div>
                                    <div className="float-start fw-bold">{data.compl}&nbsp;</div>
                                </Col>
                            </Row>
                            <Row className='mb-1'>
                                <Col lg={6} md={12}>
                                    <div className="text-gray-500 text-secondary">Cidade</div>
                                    <div className="float-start fw-bold">{data.cidade ? data.cidade + " / " + data.uf : ""}&nbsp;</div>
                                </Col>
                                <Col lg={4} md={8} className="border-start">
                                    <div className="text-gray-500 text-secondary">Bairro</div>
                                    <div className="float-start fw-bold">{data.bairro}&nbsp;</div>
                                </Col>
                                <Col lg={2} md={4} className="border-start">
                                    <div className="text-gray-500 text-secondary">Região</div>
                                    <div className="float-start fw-bold">{data.regiao}&nbsp;</div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {notification ? (<ModalNotifications show={notification} close={setNotification} texto={txt} message={msg} />) : null}
                    {editDados ? <Modals show={editDados} close={setEditDados} size="xl" icon={<FaUser />} title="Alteração de Dados do Membro"><Dados setDados={setDados} dados={dados} close={setEditDados} setData={setData} /></Modals> : null}
                    {editAddress ? <Modals show={editAddress} close={setEditAddress} size="xl" icon={<FaUser />} title="Alteração de Endereço do Membro" footer={<BtFooterEndereco />}><Endereco setDados={setDados} dados={dados} /></Modals> : null}
                    {editContacts ? <Modals show={editContacts} close={setEditContacts} size="xl" icon={<FaUser />} title="Alteração de Contato do Membro"><Contatos setDados={setDados} dados={dados} close={setEditContacts} setData={setData} /></Modals> : null}
                </>
            }
        </>
    )
}

export default CadastrosMembrosView