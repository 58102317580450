//import './sidebar.css'
import logo from '../../assets/imgs/rosto.webp'
import { FaTachometerAlt, FaTimes, FaChartPie, FaLocationArrow, FaSignOutAlt, FaSearch, FaHome, FaUser, FaPeopleCarry, FaSuperpowers, FaUsers, FaChrome } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { DecriptToken } from '../../functions';
import { MdFamilyRestroom } from 'react-icons/md';
import { BsTable } from 'react-icons/bs';

const Restrict = ({ users, children }) => {
    const tipos = ["Super", "Administrador"]
    console.log(users.includes(DecriptToken(localStorage.getItem("_token")).role))
    if (users.includes(DecriptToken(localStorage.getItem("_token")).role))
        return children

}

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
    return (
        <>
            <aside className="sidebar">
                {/* <div className="toggle">
                    <a className="burger js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
                        <span></span>
                    </a>
                </div> */}
                <div className="side-inner">
                    <div className="profile">
                        <img src={logo} alt="Image" className="img-fluid" />
                        <h3 className="name">{DecriptToken(localStorage.getItem("_token")).nome}</h3>
                        <span className="country">{DecriptToken(localStorage.getItem("_token")).funcao}</span>
                    </div>
                    <div className="nav-menu">
                        <ul>
                            {/* <li className="accordion">
                                <a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" className="collapsible">
                                    <FaHome className='me-3' />Feed
                                </a>
                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne">
                                    <div>
                                        <ul>
                                            <li><a href="#">News</a></li>
                                            <li><a href="#">Sport</a></li>
                                            <li><a href="#">Health</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className="accordion">
                                <a href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" className="collapsible">
                                    <FaSearch className='me-3' />Explore
                                </a>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingOne">
                                    <div>
                                        <ul>
                                            <li><a href="#">Interior</a></li>
                                            <li><a href="#">Food</a></li>
                                            <li><a href="#">Travel</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li> */}
                            <li><Link to="/"><FaHome className='me-3' />Home</Link></li>
                            <li><Link to="/cadastros/membros"><FaUser className='me-3' />Membros</Link></li>
                            <li><Link to="/cadastros/familias"><MdFamilyRestroom className='me-3' />Famílias</Link></li>
                            <Restrict users={["Super"]} >
                                <li><Link to="/tabelas"><BsTable className='me-3' />Tabelas</Link></li>
                                <li><Link to="/usuarios"><FaUsers className='me-3' />Usuários</Link></li>
                            </Restrict>
                            <li><Link to="/logout"><FaSignOutAlt className='me-3' />Sign out</Link></li>
                        </ul>
                    </div>
                </div>
            </aside>

            {/* <div classNameName={sidebarOpen ? "sidebar-responsive" : ""} id="sidebar">
                <div classNameName='sidebar_title'>
                    <div classNameName='sidebar_img'>
                        <img src={logo} alt='Logo' />
                        <h1>Henro Solucoes</h1>
                    </div>
                    <FaTimes size={18} classNameName="i" />
                </div>

                <div classNameName='sidebar_menu'>
                    <div classNameName='sidebar_link active_menu_link'>
                        <FaMinusSquare size={18} classNameName="i" />
                        <a href='#'>Home</a>
                    </div>
                    <h2>Dashboard</h2>
                    <div classNameName='sidebar_link'>
                        <FaTachometerAlt size={18} classNameName="i" />
                        <a href='#'>Membros</a>
                    </div>
                    <div classNameName='sidebar_link'>
                        <i classNameName='fa fa-tachometer'></i>
                        <a href='#'>Familias</a>
                    </div>
                    <div classNameName='sidebar_link'>
                        <i classNameName='fa fa-tachometer'></i>
                        <a href='#'>Programacoes</a>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Sidebar