import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'

const Header = () => {
    return (
        <Navbar bg="light" variant="light" expand="lg" className='shadow'>
            <Container>
                <Navbar.Brand href="#home">Gi7Net</Navbar.Brand>
            </Container>
        </Navbar>
    )
}

export default Header
