import React from 'react'

const UsuariosNew = () => {
    return (
        <div>
            Novo Usuario aqui
        </div>
    )
}

export default UsuariosNew
