import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

const LoginOut = () => {
    useEffect(() => {
        localStorage.removeItem("_token")
    }, [])
    return (
        <div>
            <Navigate to="/login" replace={true} />
        </div>
    )
}

export default LoginOut
